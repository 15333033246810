import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';

import { ApiContext } from '../../components/app/contexts/ApiContext';
import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';

import useAuthLogin from "../../components/app/AuthLogin";

function AdminSettings() {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const {authLogin, setAuthLogin} = useAuthLogin();

  const [login, setLogin] = useState(authLogin);

  // async function getAdminSettings(){
  //   var adminSettings = await api.getAdminSettings(authToken);
  //
  //   if(adminSettings.status == true){
  //     setName(adminSettings.name);
  //   }
  // }
  //
  // useEffect( () => {
  //
  //   getAdminSettings();
  //
  // }, []);
  //
  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    // if(id === 'name'){
    //   await setName(value);
    // }

  }
  //
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const response = await api.updateAdminSettings(
    //   authToken,
    //   name
    // );
    //
    // if( response.status == true ){
    //   setAuthName(response.name);
    //   UIkit.notification("<span uk-icon='icon: check'></span> Ваши изменения сохранены", {status: 'success', pos: 'top-center'} );
    // } else {
    //   UIkit.notification("<span uk-icon='icon: close'></span> Произошла ошибка", {status: 'danger', pos: 'top-center'});
    // }

  }


  return (
    <div className="uk-container">

      <Breadcrumbs>
        <Breadcrumb><NavLink to="/">Панель администратора</NavLink></Breadcrumb>
        <Breadcrumb><span>Настройки профиля</span></Breadcrumb>
      </Breadcrumbs>

      <h1 className="uk-heading-line uk-text-center">
        <span>Настройки профиля</span>
      </h1>

      <h1 className="uk-heading-divider"></h1>

      <form id="adminSettingsForm" className="uk-form-horizontal" onSubmit={handleSubmit}>

        <legend className="uk-legend uk-text-center uk-margin">Пожалуйста, проверьте данные своего профиля:</legend>

        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-margin uk-width-1-2" id="loginBox">
            <label className="uk-form-label uk-text-center" htmlFor="login">Ваш логин:</label>
            <div className="uk-form-controls">
              <input id="login"
                     className="uk-input"
                     name="login"
                     type="text"
                     defaultValue={login}
                     disabled={true}
                     onChange={handleInputChange}
              />
            </div>
          </div>


          <div className="uk-width-1-2" id="changePasswordBox">
            <div className="uk-margin-left uk-form-label">
              <NavLink to="/profile/password">Изменить пароль</NavLink>
            </div>
          </div>

        </div>

      </form>

    </div>

  )
}

export default AdminSettings;
