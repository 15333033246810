import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import TinyEditor from '../../../components/content/TinyEditor';


function UpsellDescription(props){

  const [description, setDescription] = props.description;

  const [contentInitial, setContentInitial] = useState(null);

  async function initContentEditor(){
    setContentInitial('');
  }

  useEffect( () => {

    initContentEditor();

  }, []);


  const handleContentEditorChange = async (value, editor) => {
    // console.log(editor);

    if(editor.id == "description"){
      setDescription(value);
    }
  }



  return (
      <div className="uk-width-1-1" id="titleBox">
        <label className="uk-form-label" htmlFor="description">Описание (HTML):</label>
        <div className="uk-form-controls">
          <TinyEditor
           id="description"
           // initialValue={contentInitial}
           value={description}
           init={{
             height: 250,
             image_uploadtab: true,
             automatic_uploads: true,
             file_picker_types: 'image',
             paste_data_images: true,
           }}
           menubar={'file edit insert view format table tools'}
           content_style={'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'}
           plugins={'advlist autolink lists link image charmap print preview ' +
                    'anchor searchreplace visualblocks code fullscreen ' +
                    'insertdatetime media table paste code help wordcount ' +
                    'emoticons'}
           toolbar={'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | image media | help'}
           onEditorChange={handleContentEditorChange}
         />
        </div>
      </div>
  );
}

export default UpsellDescription;
