import { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";

import Blocks from './blocks/Blocks';

import { ApiContext } from '../../../components/app/contexts/ApiContext';

const typesMap = {
  'good': 'Товар',
  'category': 'Категория'
};


function Goods(props){

  const api = useContext(ApiContext);

  const [goods, setGoods] = props.goods;
  const [upsellsMeta, setUpsellsMeta] = useState([]);
  const [upsellsCategoriesMeta, setUpsellsCategoriesMeta] = useState([]);


  async function getUpsellsMeta(){
    var dataFeed = await api.upsells.get(false, 'name', 'asc', false, false, false, 1, 1000);

    if(dataFeed.status === true){
      setUpsellsMeta(dataFeed.data);
    }
  }

  async function getUpsellsCategoriesMeta(){
    var dataFeed = await api.upsells_categories.get(false, 'name', 'asc', false, 1, 1000);

    if(dataFeed.status === true){
      setUpsellsCategoriesMeta(dataFeed.data);
    }
  }


  useEffect(() => {
    getUpsellsMeta();
    getUpsellsCategoriesMeta();
  }, []);


  return (
    <div className="uk-grid-small" data-uk-grid style={{maxHeight: '90%', overflowY: 'scroll'}}>

      <div className="uk-width-1-1">
        {/*<label className="uk-form-label">Допродажи:</label>*/}
        <div className="uk-form-controls">
          <Blocks
            goods={[goods, setGoods]}
            upsellsMeta={upsellsMeta}
            upsellsCategoriesMeta={upsellsCategoriesMeta}
            typesMap={typesMap}
          />
        </div>
      </div>

    </div>
  );
}

export default Goods;
