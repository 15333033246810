import { useState, useEffect, useContext, useRef } from 'react';
import { NavLink, Link, useParams, useSearchParams, useNavigate } from "react-router-dom";

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';

import Goods from '../../components/sitesCatalogues/goods/Goods';

import Upsells from '../../components/sites/upsells/Upsells';
import Variations from '../../components/sites/formulas/Variations';


import FilesModalScreen from '../../components/sitesCatalogues/modals/FilesModalScreen';

import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../components/app/contexts/ApiContext';
import { RoleContext } from '../../components/app/contexts/RoleContext';

import { roundFloat } from '../../components/app/Functions';

const headings = {
  'create': 'Создать сайт-каталог',
  'update': 'Редактировать сайт-каталог',
}

const roundPrecision = 100000;

function SiteCatalogue(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);
  const role = useContext(RoleContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState('');

  const [crmApiKey, setCrmApiKey] = useState('');
  const [crmBaseUri, setCrmBaseUri] = useState('');
  const [crmOrderType, setCrmOrderType] = useState('');
  const [crmOrderMethod, setCrmOrderMethod] = useState('');
  const [crmShop, setCrmShop] = useState('');
  const [crmBuyerId, setCrmBuyerId] = useState('');

  const [goods, setGoods] = useState([]);
  const [country, setCountry] = useState('UZ');
  const [language, setLanguage] = useState('RU');
  const [internalName, setInternalName] = useState('');
  const [url, setUrl] = useState('');

  const [catalogueOrderTotalMin, setCatalogueOrderTotalMin] = useState('');

  const mediaRef = useRef(null);
  const [media, setMedia] = useState('');
  const [file, setFile] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(false);

  const [upsells, setUpsells] = useState([]);

  const [giftSum, setGiftSum] = useState('');
  const [giftId, setGiftId] = useState('');
  const [freeDeliverySum, setFreeDeliverySum] = useState('');
  const [freeDeliveryId, setFreeDeliveryId] = useState('');
  const [botApprovementDiscount, setBotApprovementDiscount] = useState('');
  const [approvementOptions, setApprovementOptions] = useState(1);

  const [operatorComment, setOperatorComment] = useState('');

  const [created, setCreated] = useState(0);
  const [modified, setModified] = useState(0);

  const [crmBuyerIdsMeta, setCrmBuyerIdsMeta] = useState([]);

  const [modal, setModal] = useState([]);

  const params = useParams();
  const siteCatalogueId = params.siteCatalogueId;

  const navigate = useNavigate();

  const heading = siteCatalogueId > 0 ? (<>{headings.update} - <b>{name}</b></>) : headings.create;


  async function getData(){
    var data = await api.sitesCatalogues.get(siteCatalogueId);

    if(data.status !== true){
      window.location.href = '/';
    } else {

      setName(data.data.name);

      setCrmApiKey(data.data.crm.api_key);
      setCrmBaseUri(data.data.crm.base_uri);
      setCrmOrderType(data.data.crm.order_type);
      setCrmOrderMethod(data.data.crm.order_method);
      setCrmShop(data.data.crm.shop);
      setCrmBuyerId(data.data.crm.buyer_id);

      setGoods(data.data.goods);

      setCountry(data.data.country);
      setLanguage(data.data.language);

      setInternalName(data.data.internal_name);
      setUrl(data.data.url);

      setCatalogueOrderTotalMin(roundFloat(data.data.catalogue_order_total_min, roundPrecision));

      setMedia(data.data.image);

      setUpsells(data.data.upsells);

      setGiftSum(roundFloat(data.data.gift.sum, roundPrecision));
      setGiftId(data.data.gift.id);

      setFreeDeliverySum(roundFloat(data.data.free_delivery.sum, roundPrecision));
      setFreeDeliveryId(data.data.free_delivery.id);

      setBotApprovementDiscount(data.data.bot_approvement_discount);
      setApprovementOptions(data.data.approvement_options);

      setOperatorComment(data.data.operator_comment);


      setCreated(data.data.created);
      setModified(data.data.modified);

    }
  }


  async function getMeta(){
    var dataFeed = await api.users.meta();

    if(dataFeed.status === true){

      setCrmBuyerIdsMeta(dataFeed.data.buyer_id);
    }
  }


  useEffect( () => {

    getMeta();

    if(siteCatalogueId > 0){
      getData();
    }

  }, []);


  const openModal = (e) => {
    e.preventDefault();

    setModal(
      <FilesModalScreen
        siteCatalogueId={siteCatalogueId}
        modal={[modal, setModal]}
      />
    );

  };


  const handleImageEdit = (e) => {
    e?.preventDefault();

    mediaRef?.current?.click();
    return false;
  };


  const handleImageDelete = (e) => {
    e?.preventDefault();

    setMedia(null);
    setFile(false);
    setDeleteMedia(true);
    return false;
  };


  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id === 'name'){
      setName(value.replace(' ', ''));
    }

    if(id === 'crmApiKey'){
      setCrmApiKey(value);
    }

    if(id === 'crmBaseUri'){
      setCrmBaseUri(value);
    }

    if(id === 'crmOrderType'){
      setCrmOrderType(value);
    }

    if(id === 'crmOrderMethod'){
      setCrmOrderMethod(value);
    }

    if(id === 'crmShop'){
      setCrmShop(value);
    }

    if(id === 'crmBuyerId'){
      setCrmBuyerId(value);
    }

    if(id === 'country'){
      setCountry(value);
    }

    if(id === 'language'){
      setLanguage(value);
    }

    if(id === 'internalName'){
      setInternalName(value);
    }

    if(id === 'url'){
      setUrl(value);
    }

    if(id === 'catalogueOrderTotalMin'){
      setCatalogueOrderTotalMin(value);
    }

    if(id === 'media' && e.target.files){
      setFile(e.target.files[0]);
      setMedia(URL.createObjectURL(e.target.files[0]));
      setDeleteMedia(false);
    }


    if(id === 'giftSum'){
      setGiftSum(value);
    }

    if(id === 'giftId'){
      setGiftId(value.replace(' ', ''));
    }

    if(id === 'freeDeliverySum'){
      setFreeDeliverySum(value);
    }

    if(id === 'freeDeliveryId'){
      setFreeDeliveryId(value.replace(' ', ''));
    }

    if(id === 'botApprovementDiscount'){
      setBotApprovementDiscount(
        roundFloat(parseFloat(value) / 100, 100)
      );
    }

    if(id === 'approvementOptions'){
      setApprovementOptions(value);
    }

    if(id === 'operatorComment'){
      setOperatorComment(value);
    }


  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if(siteCatalogueId > 0){

      var response = await api.sitesCatalogues.update(
        siteCatalogueId,
        name,
        crmApiKey,
        crmBaseUri,
        crmOrderType,
        crmOrderMethod,
        crmShop,
        crmBuyerId,
        goods,
        country,
        language,
        internalName,
        url,
        catalogueOrderTotalMin,
        file,
        deleteMedia,
        upsells,
        giftSum,
        giftId,
        freeDeliverySum,
        freeDeliveryId,
        botApprovementDiscount,
        approvementOptions,
        operatorComment
      );

      if( response && response.status == true ){
        notifications.sitesCatalogues.notification('site_catalogue_updated');
      } else {
        notifications.sitesCatalogues.notification(response.error);
      }
    } else {
      var response = await api.sitesCatalogues.create(
        name,
        crmApiKey,
        crmBaseUri,
        crmOrderType,
        crmOrderMethod,
        crmShop,
        crmBuyerId,
        goods,
        country,
        language,
        internalName,
        url,
        catalogueOrderTotalMin,
        media,
        upsells,
        giftSum,
        giftId,
        freeDeliverySum,
        freeDeliveryId,
        botApprovementDiscount,
        approvementOptions,
        operatorComment
      );

      if( response && response.status === true ){
        notifications.sitesCatalogues.notification('site_catalogue_created');

        setTimeout(function () {
          navigate(['', 'sites_catalogues', response.id].join('/'))
        }, 2500);

      } else {
        notifications.sitesCatalogues.notification(response.error);
      }
    }
  }


  function renderCrmBuyerIdsOptions(){
    var options = [];
    options.push(
      <option key='crm-buyer-id-default' value="">-- Buyer ID --</option>
    );


    Object.keys(crmBuyerIdsMeta).forEach((item, i) => {
      options.push(
        <option key={'crm-buyer-id-' + item} value={item}>{item}</option>
      );
    });

    return options;
  }


  return (
    <div className="uk-container" id="site-catalogue-form">

      <div className="uk-grid uk-grid-small" data-uk-grid>
        <div className="uk-width-2-3">
          <Breadcrumbs>
            <Breadcrumb><NavLink to="/">Панель администратора</NavLink></Breadcrumb>
            <Breadcrumb><NavLink to="/sites_catalogues">Сайты-каталоги</NavLink></Breadcrumb>
            <Breadcrumb><span>{heading}</span></Breadcrumb>
          </Breadcrumbs>
        </div>

        <div className="uk-width-1-3 uk-text-right">
          {(siteCatalogueId > 0) && (
            <button
              type="button"
              className="uk-button uk-button-default"
              onClick={openModal}
              style={{
                top: '-6px',
                position: 'relative'
              }}
            >
              <span className="uk-icon" data-uk-icon="download"></span> Файлы интеграции
            </button>
          )}
        </div>
      </div>



      <h1 className="uk-heading-line uk-text-center">
        <span>{heading}</span>
      </h1>

      <h1 className="uk-heading-divider"></h1>

      <form id="editForm" className="uk-form-vertical uk-grid-small" data-uk-grid onSubmit={handleSubmit}>

        <div className="uk-width-3-5">

          <div className="uk-grid-small" data-uk-grid>

            <div className="uk-width-2-3">
              <h2 className="uk-text-left">Общие настройки</h2>
            </div>

            <div className="uk-width-1-1 uk-grid uk-grid-small">

              <div className="uk-width-1-2">
                <label className="uk-form-label" htmlFor="media">Изображение:</label>
                <div data-uk-lightbox className="uk-background-muted uk-border-rounded uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-position-relative" style={{width: '100%', height: '300px'}} id="image-loader">
                  {media ? (
                      <NavLink to={media}>
                        <picture>
                          <img className="uk-object-cover" src={media} />
                        </picture>
                      </NavLink>
                    ) : (
                      <span>Изображение по умолчанию</span>
                    )
                  }
                  <div className="uk-position-absolute uk-flex uk-flex-row uk-flex-center uk-flex-middle">
                    <button
                      title="Редактировать"
                      data-uk-tooltip="Редактировать"
                      data-uk-icon="icon: pencil"
                      className="uk-icon uk-icon-button uk-box-shadow-large action-edit"
                      onClick={handleImageEdit}
                    >
                    </button>

                    { (!deleteMedia && (media || file)) && (
                      <button
                        title="Удалить"
                        data-uk-tooltip="Удалить"
                        data-uk-icon="icon: trash"
                        className="uk-icon uk-icon-button uk-box-shadow-large action-delete"
                        onClick={handleImageDelete}
                      >
                      </button>

                    )}
                  </div>
                </div>

                <input
                  ref={mediaRef}
                  id="media"
                  name="media"
                  type="file"
                  accept="image/*"
                  onChange={handleInputChange}
                  hidden={true}
                />
              </div>

              <div className="uk-width-1-2 uk-flex uk-flex-column uk-flex-between">

                <div className="uk-width-1-1" id="nameBox">
                  <label className="uk-form-label" htmlFor="name">Идентификатор:</label>
                  <div className="uk-form-controls">
                    <input
                      id="name"
                      className="uk-input"
                      name="name"
                      type="text"
                      placeholder="site-id"
                      onChange={handleInputChange}
                      value={name}
                      required={true}
                    />
                  </div>
                </div>

                <div className="uk-width-1-1" id="buyerIdBox">
                  <label className="uk-form-label" htmlFor="crmBuyerId">Buyer ID:</label>
                  <div className="uk-form-controls">
                    {(role == 'user') && (
                      <input
                        id="crmBuyerId"
                        className="uk-input"
                        name="crmBuyerId"
                        type="text"
                        onChange={handleInputChange}
                        value={crmBuyerId}
                        readOnly={true}
                      />
                    )}
                    {(role == 'owner' || role == 'admin') && (
                      <select
                        id="crmBuyerId"
                        className="uk-select"
                        name="crmBuyerId"
                        onChange={handleInputChange}
                        value={crmBuyerId}
                      >
                        {renderCrmBuyerIdsOptions()}
                      </select>
                    )}
                  </div>
                </div>

                <div className="uk-width-1-1" id="internalNameBox">
                  <label className="uk-form-label" htmlFor="internalName">Название оффера:</label>
                  <div className="uk-form-controls">
                    <input
                      id="internalName"
                      className="uk-input"
                      name="internalName"
                      type="text"
                      placeholder="Site #1"
                      onChange={handleInputChange}
                      value={internalName}
                    />
                  </div>
                </div>

                <div className="uk-width-1-1" id="urlBox">
                  <label className="uk-form-label" htmlFor="url">Ссылка на сайт:</label>
                  <div className="uk-form-controls">
                    <input
                      id="url"
                      className="uk-input"
                      name="url"
                      type="text"
                      placeholder="https://example.com"
                      onChange={handleInputChange}
                      value={url}
                    />
                  </div>
                </div>
              </div>

              <div className="uk-width-1-1 uk-margin-small-top">
                <label className="uk-form-label" htmlFor="operatorComment">Комментарий для оператора:</label>
                <div className="uk-form-controls">
                  <input
                    id="operatorComment"
                    className="uk-input"
                    name="operatorComment"
                    type="text"
                    placeholder="Максимум 250 символов"
                    maxLength="250"
                    onChange={handleInputChange}
                    value={operatorComment}
                  />
                </div>
              </div>

            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Вспомогательные поля для интеграции с CRM. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="crmBaseUri">Ссылка на API:</label>
              <div className="uk-form-controls">
                <input
                  id="crmBaseUri"
                  className="uk-input"
                  name="crmBaseUri"
                  type="text"
                  placeholder="https://crm.com/"
                  onChange={handleInputChange}
                  value={crmBaseUri}
                />
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="crmApiKey">Ключ API:</label>
              <div className="uk-form-controls">
                <input
                  id="crmApiKey"
                  className="uk-input"
                  name="crmApiKey"
                  type="text"
                  placeholder="crm-api-key"
                  onChange={handleInputChange}
                  value={crmApiKey}
                />
              </div>
            </div>

            <div className="uk-width-1-3">
              <label className="uk-form-label" htmlFor="crmShop">Shop:</label>
              <div className="uk-form-controls">
                <input
                  id="crmShop"
                  className="uk-input"
                  name="crmShop"
                  type="text"
                  placeholder="shop"
                  onChange={handleInputChange}
                  value={crmShop}
                />
              </div>
            </div>

            <div className="uk-width-1-3">
              <label className="uk-form-label" htmlFor="crmOrderType">Order type:</label>
              <div className="uk-form-controls">
                <input
                  id="crmOrderType"
                  className="uk-input"
                  name="crmOrderType"
                  type="text"
                  placeholder="eshop-individual"
                  onChange={handleInputChange}
                  value={crmOrderType}
                />
              </div>
            </div>

            <div className="uk-width-1-3">
              <label className="uk-form-label" htmlFor="crmOrderMethod">Order method:</label>
              <div className="uk-form-controls">
                <input
                  id="crmOrderMethod"
                  className="uk-input"
                  name="crmOrderMethod"
                  type="text"
                  placeholder="landing-page"
                  onChange={handleInputChange}
                  value={crmOrderMethod}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="country">Страна (ISO):</label>
              <div className="uk-form-controls">
                <input
                  id="country"
                  className="uk-input"
                  name="country"
                  type="text"
                  placeholder="UZ"
                  onChange={handleInputChange}
                  value={country}
                />
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="language">Язык (ISO):</label>
              <div className="uk-form-controls">
                <input
                  id="language"
                  className="uk-input"
                  name="language"
                  type="text"
                  placeholder="RU"
                  onChange={handleInputChange}
                  value={language}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Настройки минимальной суммы заказа. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="catalogueOrderTotalMin">Минимальная сумма заказа:</label>
              <div className="uk-form-controls">
                <input
                  id="catalogueOrderTotalMin"
                  className="uk-input"
                  name="catalogueOrderTotalMin"
                  type="number"
                  min="0"
                  step="0.00001"
                  placeholder="Стоимость по ценам CRM (/1000)"
                  onChange={handleInputChange}
                  value={catalogueOrderTotalMin}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Настройки подарка для страницы допродаж. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="giftSum">Стоимость допродаж для активации подарка:</label>
              <div className="uk-form-controls">
                <input
                  id="giftSum"
                  className="uk-input"
                  name="giftSum"
                  type="number"
                  min="0"
                  step="0.00001"
                  placeholder="Стоимость по ценам CRM (/1000)"
                  onChange={handleInputChange}
                  value={giftSum}
                />
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="giftId">Внешний ID подарка:</label>
              <div className="uk-form-controls">
                <input
                  id="giftId"
                  className="uk-input"
                  name="giftId"
                  type="text"
                  placeholder="gift-crm-external-id"
                  onChange={handleInputChange}
                  value={giftId}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Настройки бесплатной доставки для страницы допродаж. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="freeDeliverySum">Стоимость допродаж для активации доставки:</label>
              <div className="uk-form-controls">
                <input
                  id="freeDeliverySum"
                  className="uk-input"
                  name="freeDeliverySum"
                  type="number"
                  min="0"
                  step="0.00001"
                  placeholder="Стоимость по ценам CRM (/1000)"
                  onChange={handleInputChange}
                  value={freeDeliverySum}
                />
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="freeDeliveryId">Внешний ID бесплатной доставки:</label>
              <div className="uk-form-controls">
                <input
                  id="freeDeliveryId"
                  className="uk-input"
                  name="freeDeliveryId"
                  type="text"
                  placeholder="free-delivery-crm-external-id"
                  onChange={handleInputChange}
                  value={freeDeliveryId}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Настройки для метода подтверждения заказа через TG-бот. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">

              <label className="uk-form-label" htmlFor="approvementOptions">Статус:</label>
              <div className="uk-form-controls">
                <select
                  id="approvementOptions"
                  className="uk-select"
                  name="approvementOptions"
                  value={approvementOptions}
                  onChange={handleInputChange}
                >
                  <option value="0">Не активен</option>
                  <option value="1">Активен</option>
                </select>
              </div>
            </div>

            {/* <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="botApprovementDiscount">Скидка, %:</label>
              <div className="uk-form-controls">
                <input
                  id="botApprovementDiscount"
                  className="uk-input"
                  name="botApprovementDiscount"
                  type="number"
                  min="0"
                  step="0.1"
                  placeholder="5"
                  onChange={handleInputChange}
                  value={roundFloat(botApprovementDiscount * 100, 100) || ''}
                />
              </div>
            </div> */}

            <div className="uk-width-1-1">
              <hr />
            </div>


          </div>

        </div>


        <div className="uk-width-2-5 uk-position-relative">
          <div className="uk-position-relative uk-width-1-1 uk-padding-small uk-padding-remove-top" style={{height: '50%', maxHeight: '50%'}}>
            <div className="uk-width-1-1">
              <h2 className="uk-text-left">Товары</h2>
            </div>
            <Goods
              goods={[goods, setGoods]}
            />
          </div>

          <div className="uk-position-relative uk-width-1-1 uk-padding-small uk-padding-remove-top" style={{height: '50%', maxHeight: '50%'}}>
            <div className="uk-width-1-1">
              <h2 className="uk-text-left">Допродажи</h2>
            </div>
            <Upsells
              upsells={[upsells, setUpsells]}
            />
          </div>

        </div>


        <div className="uk-width-1-1">
          <button id='submitButton' type="submit" className="uk-button uk-button-primary uk-align-center custom-button-success" onSubmit={handleSubmit}>Сохранить</button>
        </div>

      </form>

      {(siteCatalogueId > 0) && (modal)}

    </div>

  )
}

export default SiteCatalogue;
