import { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";

import { renderDateTime } from '../../../components/app/Functions';

import { NotificationsContext } from '../../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../../components/app/contexts/ApiContext';


function CategoriesTablePosition(props){

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const index = props.index;
  const item = props.item;
  const data = props.data;
  const openModal = props.openModal;
  const handleSingleSelection = props.handleSingleSelection;
  const getData = props.getData;
  const filterOrderBy = props.filterOrderBy;
  const filterOrder = props.filterOrder;

  // const handleArrowUp = async (e) => {
  //
  //   if(index === 0){
  //     return false;
  //   }
  //
  //   var prevItemIndex = index - 1;
  //   var prevItem = data[prevItemIndex];
  //
  //   var curItemIndex = index;
  //   var curItem = data[curItemIndex];
  //
  //   const responsePrev = await api.ecommerce.categories.update(
  //     siteId,
  //     versionId,
  //     prevItem.id,
  //     false,
  //     curItem.sort,
  //     false
  //   );
  //
  //   const responseCur = await api.ecommerce.categories.update(
  //     siteId,
  //     versionId,
  //     curItem.id,
  //     false,
  //     prevItem.sort,
  //     false
  //   );
  //
  //   if(responsePrev.status === true && responseCur.status === true){
  //     await getData();
  //   }
  //
  // }
  //
  // const handleArrowDown = async (e) => {
  //
  //   if( (index + 1) === data.length){
  //     return false;
  //   }
  //
  //   var nextItemIndex = index + 1;
  //   var nextItem = data[nextItemIndex];
  //
  //   var curItemIndex = index;
  //   var curItem = data[curItemIndex];
  //
  //   const responseNext = await api.ecommerce.categories.update(
  //     siteId,
  //     versionId,
  //     nextItem.id,
  //     false,
  //     curItem.sort,
  //     false
  //   );
  //
  //   const responseCur = await api.ecommerce.categories.update(
  //     siteId,
  //     versionId,
  //     curItem.id,
  //     false,
  //     nextItem.sort,
  //     false
  //   );
  //
  //   if(responseNext.status === true && responseCur.status === true){
  //     await getData();
  //   }
  // }
  //
  //
  // function renderArrows(){
  //
  //   if(filterOrderBy != 'sort' || filterOrder != 'asc'){
  //     return [];
  //   }
  //
  //   var arrowUp = null;
  //   var arrowDown = null;
  //
  //   if(index !== 0){
  //     arrowUp = (
  //       <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-up" onClick={handleArrowUp}></span>
  //     );
  //   }
  //
  //   if( (index + 1) !== data.length){
  //     arrowDown = (
  //       <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-down" onClick={handleArrowDown}></span>
  //     );
  //   }
  //
  //   return (
  //     <>
  //       {arrowUp}
  //       {arrowDown}
  //     </>
  //   );
  // }

  return (
    <tr key={item.id}>
      <td>
        <input type="checkbox" name="deleteItems[]" value={item.id} onChange={handleSingleSelection}/>
      </td>
      {/*<td className="uk-padding-remove">
        <div className="arrows">
          {renderArrows()}
        </div>
      </td>*/}
      <td>
        {item.id}
      </td>
      <td>
        {item.name}
      </td>
      <td className="uk-text-meta">
        {renderDateTime(item.created)}
      </td>
      <td className="uk-text-meta">
        {renderDateTime(item.modified)}
      </td>
      <td className="uk-text-center">
        <button
          className="uk-icon-button"
          uk-icon="pencil"
          data-upsells-category-id={item.id}
          onClick={openModal}
          data-uk-tooltip="Редактировать"
        ></button>
      </td>
    </tr>
  );
}

export default CategoriesTablePosition;
