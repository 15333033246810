import AbstractNotifications from './AbstractNotifications';

class SitesNotifications extends AbstractNotifications {
  messages = {
    'site_created': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Сайт создан'
    },
    'site_updated': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },
    'site_deleted': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },

    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    },
    'existing_site_name': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Сайт с таким идентификатором уже существует'
    },
    'invalid_default_price': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Цена товара по умолчанию должна быть >= 0'
    },
    'invalid_default_quantity': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Количество товара по умолчанию должно быть > 0'
    },
    'similar_variations': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Идентификаторы вариаций в конструкторе не могут повторяться'
    },
    'invalid_calculator_ranges': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Пожалуйста, проверьте границы расчета для типа сайта Калькулятор'
    },
    'invalid_calculator_price': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Цена позиции расчета должна быть > 0'
    },
    'invalid_calculator_delivery': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Стоимость доставки позиции расчета должна быть > 0'
    },
    'invalid_variations_price': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Цена вариации должна быть >= 0'
    },
    'invalid_variations_quantity': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Количество вариации должно быть > 0'
    },
    'invalid_variations_external_id': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Пожалуйста, укажите внешний ID для всех вариаций'
    },

    'invalid_gift_sum': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Стоимость для подарка должна быть > 0'
    },

    'invalid_free_delivery_sum': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Стоимость для бесплатной доставки должна быть > 0'
    },

    'invalid_bot_approvement_discount': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Стоимость для бесплатной доставки должна быть > 0'
    },

  }
}

export default SitesNotifications;
