import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../../components/app/Functions';
import ServerLogsTableFilter from './ServerLogsTableFilter';
import TablePagination from '../../../components/common/tables/TablePagination';
import ServerLogsTablePosition from './ServerLogsTablePosition';

import { NotificationsContext } from '../../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../../components/app/contexts/ApiContext';



function ServerLogsTable() {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const [data, setData] = useState([]);

  const params = useParams();
  const pixel = params.pixel ? params.pixel : false;

  const [filterOrderBy, setFilterOrderBy] = useState('created');
  const [filterOrder, setFilterOrder] = useState('desc');
  const [filterSearch, setFilterSearch] = useState('');
  const [filterType, setFilterType] = useState(false);
  const [filterPixel, setFilterPixel] = useState(pixel);
  const [filterResponseCode, setFilterResponseCode] = useState(false);
  const [filterCreated, setFilterCreated] = useState('');

  const [filterLimit, setFilterLimit] = useState(100);
  const [filterPage, setFilterPage] = useState(1);
  const [filterTotalPages, setFilterTotalPages] = useState(1);

  const [typesMeta, setTypesMeta] = useState({});
  const [pixelsMeta, setPixelsMeta] = useState({});
  const [responseCodesMeta, setResponseCodesMeta] = useState({});


  async function getData(){

    var dataFeed = await api.facebook.serverLogs.get(false, filterOrderBy, filterOrder, filterSearch, filterType, filterPixel, filterResponseCode, filterCreated, filterPage, filterLimit);

    if(dataFeed.status == true){
      await setData(dataFeed.data);
      if(dataFeed.pagination.page){
        await setFilterTotalPages(dataFeed.pagination.total);
      }
    }
  }


  async function getMeta(){

    var dataFeed = await api.facebook.serverLogs.meta();

    if(dataFeed.status == true){
      await setTypesMeta(dataFeed.data.type);
      await setPixelsMeta(dataFeed.data.pixel);
      await setResponseCodesMeta(dataFeed.data.response_code);
    }
  }

  useEffect(() => {
    getMeta();
  }, []);

  useEffect( () => {
    getData();
  }, [filterOrderBy, filterOrder, filterSearch, filterType, filterPixel, filterResponseCode, filterCreated, filterPage, filterLimit]);


  function renderTableRows(){

    var result = [];

    data.forEach((item, i) => {

      result.push(
        <ServerLogsTablePosition
          item={item}
          key={item.id}
        />
      );
    });


    if(result.length == 0){
      result.push(
        <tr key="no-entries-found" className="uk-text-lead uk-text-center uk-margin">
          <td colSpan="7">
            Нет данных
          </td>
        </tr>
      )
    }

    return result;
  }


  return (

    <>
      <div className="uk-grid-collapse" data-uk-grid>

        <div className="uk-width-1-1">

          <ServerLogsTableFilter
            filterOrderBy={[filterOrderBy, setFilterOrderBy]}
            filterOrder={[filterOrder, setFilterOrder]}
            filterSearch={[filterSearch, setFilterSearch]}
            filterType={[filterType, setFilterType]}
            filterPixel={[filterPixel, setFilterPixel]}
            filterResponseCode={[filterResponseCode, setFilterResponseCode]}
            filterCreated={[filterCreated, setFilterCreated]}
            filterPage={[filterPage, setFilterPage]}
            typesMeta={typesMeta}
            pixelsMeta={pixelsMeta}
            responseCodesMeta={responseCodesMeta}
            getData={getData}
          />

        </div>

      </div>

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />

      <table className="uk-table uk-table-divider uk-table-middle custom-table server-logs-table">
        <thead>
          <tr>
            <th>Создан</th>
            <th>Тип</th>
            <th>Пиксель</th>
            <th>Event ID</th>
            <th>Запрос</th>
            <th>Ответ</th>
            <th>Код ответа</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />
    </>

  )
}

export default ServerLogsTable;
