import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { renderDateTimestamp, renderDateInputTime, roundFloat } from '../../../../../components/app/Functions';

const roundPrecision = 100000;

function Content(props){

  const type = props.type;
  const index = props.index;
  const subIndex = props.subIndex;

  const [formula, setFormula] = props.formula;


  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id === "formula[" + index + "][options][" + subIndex +"][price]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].price = value !== '' ? roundFloat(value, roundPrecision) : '';
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][quantity]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].quantity = parseInt(value) ? parseInt(value) : '';
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][external_id]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].external_id = value.replace(' ', '');
      setFormula(formulaNew);
    }


  }




  return (
    <>

    <div className="uk-grid-small" data-uk-grid>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][price]"}>Цена за 1 штуку:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][price]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][price]"}
            type="number"
            min="0"
            step="0.00001"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].price}
            placeholder={'Для заказа CRM'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][quantity]"}>Количество:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][quantity]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][quantity]"}
            type="number"
            min="1"
            step="1"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].quantity}
            placeholder={'Для заказа CRM'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][external_id]"}>Внешний ID:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][external_id]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][external_id]"}
            type="text"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].external_id}
            placeholder={'Для заказа CRM'}
            required={true}
          />
        </div>
      </div>

    </div>

    </>
  );
}

export default Content;
