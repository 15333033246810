import { useState, createContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ContainerAdmin from '../components/container/ContainerAdmin';
import Home from '../pages/home/Home';
import DashboardAdmin from '../pages/home/DashboardAdmin';
import NoPage from '../pages/nopage/NoPage';
import LoginAdmin from '../pages/login/LoginAdmin';
import LogoutAdmin from '../pages/logout/LogoutAdmin';
import AdminSettings from '../pages/adminSettings/AdminSettings';
import AdminChangePassword from '../pages/adminSettings/AdminChangePassword';

import Users from '../pages/users/Users';

import Logs from '../pages/logs/Logs';

import Categories from '../pages/upsells/Categories';

import Upsells from '../pages/upsells/Upsells';
import Upsell from '../pages/upsells/Upsell';

import Sites from '../pages/sites/Sites';
import Site from '../pages/sites/Site';

import Settings from '../pages/systemSettings/Settings';

import PixelsTokens from '../pages/facebook/PixelsTokens';
import ServerLogs from '../pages/facebook/ServerLogs';

import SitesCatalogues from '../pages/sitesCatalogues/SitesCatalogues';
import SiteCatalogue from '../pages/sitesCatalogues/SiteCatalogue';



import useAuthToken from '../components/app/AuthToken';
import useAuthRole from '../components/app/AuthRole';

function Main() {

  const {authToken, setAuthToken} = useAuthToken();
  const {authRole, setAuthRole} = useAuthRole();

  return (

    <Routes>

      <Route path="/" element={<ContainerAdmin />}>
        <Route index element={authToken !== null ? <DashboardAdmin /> : <Home />}/>

        <Route path="/login" element={<LoginAdmin />}/>
        <Route path="/logout" element={<LogoutAdmin />}/>

        <Route path="/*" element={<NoPage />}/>

      </Route>

      <Route path="/" element={authToken !== null ? <ContainerAdmin /> : <Navigate to="/login" />}>
        <Route path="/" element={<DashboardAdmin />}/>

        {(authRole == 'owner') && (

          <>
            <Route path="/profile" element={<AdminSettings />}/>
            <Route path="/profile/password" element={<AdminChangePassword />}/>

            <Route path="/users" element={<Users />}/>

            <Route path="/logs" element={<Logs />}/>
          </>

        )}

        {(authRole == 'owner' || authRole == 'admin') && (
          <>
            <Route path="/upsells/categories" element={<Categories />}/>
            <Route path="/upsells" element={<Upsells />}/>
            <Route path="/upsells/:upsellId" element={<Upsell />}/>

            <Route path="/settings" element={<Settings />}/>
          </>
        )}


        <Route path="/sites" element={<Sites />}/>
        <Route path="/sites/:siteId" element={<Site />}/>

        <Route path="/sites_catalogues" element={<SitesCatalogues />}/>
        <Route path="/sites_catalogues/:siteCatalogueId" element={<SiteCatalogue />}/>


        <Route path="/facebook/pixels_tokens" element={<PixelsTokens />}/>

        {(authRole == 'owner') && (
          <Route path="/facebook/server_logs" element={<ServerLogs />}/>
        )}

        <Route path="/facebook/server_logs/:pixel" element={<ServerLogs />}/>


      </Route>

    </Routes>

  );


}

export default Main;
