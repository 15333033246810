import AbstractNotifications from '../AbstractNotifications';

class FilesNotifications extends AbstractNotifications {
  messages = {
    'file_copied_to_clipboard': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Исходный код файла скопирован в буфер обмена'
    },
    'invalid_facebook_pixel_id': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Пиксель должен быть создан в разделе <a target="_blank" href="/facebook/pixels_tokens">Facebook пиксели/токены</a>: '
    },
  }
}

export default FilesNotifications;
