import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../components/app/Functions';
import SitesCataloguesTableFilter from './SitesCataloguesTableFilter';
import TablePagination from '../../components/common/tables/TablePagination';
import SitesCataloguesTablePosition from './SitesCataloguesTablePosition';

import FilesModalScreen from './modals/FilesModalScreen';

import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../components/app/contexts/ApiContext';
import { RoleContext } from '../../components/app/contexts/RoleContext';


function SitesCataloguesTable(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);
  const role = useContext(RoleContext);

  const [data, setData] = useState([]);

  const [filterOrderBy, setFilterOrderBy] = useState('name');
  const [filterOrder, setFilterOrder] = useState('asc');
  const [filterSearch, setFilterSearch] = useState('');
  const [filterCrmBuyerId, setFilterCrmBuyerId] = useState(false);
  const [filterGoodsCategoryId, setFilterGoodsCategoryId] = useState('');
  const [filterLimit, setFilterLimit] = useState(50);
  const [filterPage, setFilterPage] = useState(1);
  const [filterTotalPages, setFilterTotalPages] = useState(1);

  const [crmBuyerIdsMeta, setCrmBuyerIdsMeta] = useState([]);

  const [upsellsCategoriesMeta, setUpsellsCategoriesMeta] = useState([]);

  const [modal, setModal] = useState([]);


  async function getData(){

    var dataFeed = await api.sitesCatalogues.get(false, filterOrderBy, filterOrder, filterSearch, filterCrmBuyerId, filterGoodsCategoryId, filterPage, filterLimit);

    if(dataFeed.status == true){
      await setData(dataFeed.data);
      if(dataFeed.pagination.page){
        await setFilterTotalPages(dataFeed.pagination.total);
      }
    }
  }


  async function getMeta(){

    var dataFeed = await api.sitesCatalogues.meta();

    if(dataFeed.status == true){
      await setCrmBuyerIdsMeta(dataFeed.data.crm_buyer_id);
    }
  }


  async function getUpsellsCategoriesMeta(){

    var dataFeed = await api.upsells_categories.get(false, 'name', 'asc', false, 1, 1000);

    if(dataFeed.status == true){
      await setUpsellsCategoriesMeta(dataFeed.data);
    }
  }



  useEffect( () => {
    getUpsellsCategoriesMeta();
    getMeta();
  }, []);


  useEffect( () => {
    getData();
  }, [filterOrderBy, filterOrder, filterSearch, filterCrmBuyerId, filterGoodsCategoryId, filterPage, filterLimit]);



  const handleDeletion = async (e) => {
    e.preventDefault();

    var selected = document.querySelectorAll('[name="deleteItems[]"]:checked');

    await selected.forEach(async (item, i) => {
      const response = await api.sitesCatalogues.delete(
        item.value
      );

    });

    notifications.sitesCatalogues.notification('site_catalogue_deleted');

    setTimeout(async function(){
      await getData();
      await getMeta();
    }, 2500);
  }


  function renderTableRows(){

    var result = [];

    data.forEach((item, i) => {

      const openModal = (e) => {
        e.preventDefault();

        setModal(
          <FilesModalScreen
            siteCatalogueId={item.id}
            modal={[modal, setModal]}
          />
        );

      }

      result.push(
        <SitesCataloguesTablePosition
          key={String('site-catalogue-').concat(i)}
          item={item}
          handleSingleSelection={handleSingleSelection}
          upsellsCategoriesMeta={upsellsCategoriesMeta}
          openModal={openModal}
        />
      );
    });


    if(result.length == 0){
      result.push(
        <tr key="no-entries-found" className="uk-text-lead uk-text-center uk-margin">
          <td colSpan="10">
            Нет данных
          </td>
        </tr>
      )
    }

    return result;
  }


  const handleSingleSelection = async (e) => {

    var elem = e.currentTarget;
    var cond = elem.checked;
    var deleteAllCheckbox = document.querySelector('table.custom-table thead input[name="deleteAll"]');

    var checkboxes = document.querySelectorAll('table.custom-table tbody input[name="deleteItems[]"]');
    var checkboxesChecked = document.querySelectorAll('table.custom-table tbody input[name="deleteItems[]"]:checked');


    var deleteAllCond = false;
    if(deleteAllCheckbox){
      deleteAllCond = deleteAllCheckbox.checked;
    }

    if(cond == false && deleteAllCond == true){
      deleteAllCheckbox.checked = false;
    }

    if(checkboxes.length == checkboxesChecked.length){
      deleteAllCheckbox.checked = true;
    }

  }


  const handleBulkSelection = async (e) => {

    var elem = e.currentTarget;
    var cond = elem.checked;

    var checkboxes = document.querySelectorAll('table.custom-table tbody input[name="deleteItems[]"]');

    checkboxes.forEach((item, i) => {
      item.checked = cond;
    });

  }


  return (

    <>
      <div className="uk-grid-collapse" data-uk-grid>

        <div className="uk-width-1-4">
          <div className="uk-text-left">
            <NavLink className="uk-button uk-button-primary custom-button-success" to={"/sites_catalogues/create"}>Добавить</NavLink>
            {(role == 'owner') && (
              <button className="uk-button uk-button-danger custom-button-danger" onClick={handleDeletion}>Удалить</button>
            )}
          </div>
        </div>

        <div className="uk-width-3-4">

          <SitesCataloguesTableFilter
            filterOrderBy={[filterOrderBy, setFilterOrderBy]}
            filterOrder={[filterOrder, setFilterOrder]}
            filterSearch={[filterSearch, setFilterSearch]}
            filterCrmBuyerId={[filterCrmBuyerId, setFilterCrmBuyerId]}
            filterGoodsCategoryId={[filterGoodsCategoryId, setFilterGoodsCategoryId]}
            filterPage={[filterPage, setFilterPage]}
            getData={getData}
            crmBuyerIdsMeta={crmBuyerIdsMeta}
            upsellsCategoriesMeta={upsellsCategoriesMeta}
          />

        </div>

      </div>

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />

      <table className="uk-table uk-table-divider uk-table-middle custom-table sites-table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" name="deleteAll" value={true} onChange={handleBulkSelection} />
            </th>
            <th>ID</th>
            <th className="uk-text-center">Идентификатор</th>
            <th className="uk-text-center">Название</th>
            <th className="uk-text-center">Buyer ID</th>
            <th className="uk-text-center">Мин. сумма заказа</th>
            <th className="uk-text-center">Категории товаров</th>
            <th>Создан</th>
            <th>Обновлен</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />

      {modal}
    </>

  )
}

export default SitesCataloguesTable;
