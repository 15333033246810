import  { useState, useContext, useEffect } from "react";
import  { NavLink } from "react-router-dom";
import useAuthToken from "../app/AuthToken";
import useAuthLogin from "../app/AuthLogin";

import { NotificationsContext } from '../app/contexts/NotificationsContext';
import { ApiContext } from '../app/contexts/ApiContext';
import { RoleContext } from '../app/contexts/RoleContext';


function NavbarAdmin() {

  const role = useContext(RoleContext);

  const notifications = useContext(NotificationsContext);
  const {authToken, setAuthToken} = useAuthToken();
  const {authLogin, setAuthLogin} = useAuthLogin();

  const [adminMenu, setAdminMenu] = useState([]);
  const [adminRightMenu, setAdminRightMenu] = useState([]);

  const api = useContext(ApiContext);


  useEffect(() => {
    if(authToken != null){
      setAdminMenu(
        <>
          {(role == 'owner' || role == 'admin') && (
            <>
              <li>
                <NavLink to="/upsells">Допродажи</NavLink>
                <div className="uk-navbar-dropdown">
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    <li>
                      <NavLink to="/upsells">Товары</NavLink>
                    </li>
                    <li>
                      <NavLink to="/upsells/categories">Категории</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          )}
          <li><NavLink to="/sites">Сайты</NavLink></li>
          <li><NavLink to="/sites_catalogues">Сайты-каталоги</NavLink></li>
        </>
      );

      setAdminRightMenu(
        <>

          {(role == 'owner' || role == 'admin') && (
            <li>
              <NavLink className="custom-nav-icon" uk-icon="cog" data-uk-tooltip="Настройки системы" to="/settings"></NavLink>
            </li>
          )}

          {(role == 'owner') && (
            <>
              <li>
                <NavLink className="custom-nav-icon" uk-icon="user" data-uk-tooltip="Настройки профиля" to="/profile"></NavLink>
              </li>
            </>
          )}

          <li>
            <NavLink className="custom-nav-icon" uk-icon="facebook" data-uk-tooltip="Facebook пиксели/токены" to="/facebook/pixels_tokens"></NavLink>
          </li>

          {(role == 'owner') && (
            <>
              <li>
                <NavLink className="custom-nav-icon" uk-icon="users" data-uk-tooltip="Пользователи" to="/users"></NavLink>
              </li>
              <li>
                <NavLink className="custom-nav-icon" uk-icon="file-text" to="/logs"></NavLink>
                <div className="uk-navbar-dropdown">
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    <li>
                      <NavLink to="/logs">Логи системы</NavLink>
                    </li>
                    <li>
                      <NavLink to="/facebook/server_logs">Логи Facebook</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          )}

          <li>
            <NavLink className="custom-nav-icon" uk-icon="sign-out" data-uk-tooltip="Выход" to="/logout"></NavLink>
          </li>
        </>
      );
    }
  }, []);

  const greetingsName = authLogin;

  return (
    <nav className="uk-navbar-container uk-margin custom-navbar" uk-navbar="true">
      <div className="uk-navbar-left">

        <NavLink className="uk-navbar-item uk-logo" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-seam" viewBox="0 0 16 16">
            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
          </svg>
        </NavLink>

        <ul className="uk-navbar-nav" data-uk-dropnav="dropbar: true">
          {adminMenu}
        </ul>

      </div>

      <div className="uk-navbar-right">

        <div className="custom-greetings" hidden={authToken == null ? true : false}>Здравствуйте, <b>{greetingsName}</b></div>

        <ul className="uk-navbar-nav">
          {(authToken == null) && (
            <li>
              <NavLink to="/login">Вход</NavLink>
            </li>
          )}

          {adminRightMenu}

        </ul>

      </div>
    </nav>
  )
}

export default NavbarAdmin;
