import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Category from "./Category";

function Categories(props){

  const [categories, setCategories] = props.categories;
  const categoriesMeta = props.categoriesMeta;


  const handleAddCategory = (event) => {

    var categoriesNew = [...categories];

    categoriesNew.push('')

    setCategories(categoriesNew);
  }


  function renderCategories(){

    var options = [];

    categories.forEach((item, i) => {

      // Checks for the existing category
      // var curCategoryMeta = false;
      // for (var i = 0; i < categoriesMeta.length; i++) {
      //   if(parseInt(categoriesMeta[i]['id']) == parseInt(item)){
      //     curCategoryMeta = categoriesMeta[i];
      //     break;
      //   }
      // }
      //
      // if(curCategoryMeta === false){
      //   return;
      // }

      options.push(
        <li key={'upsell-category-' + i}>
          <Category
            categories={[categories, setCategories]}
            categoriesMeta={categoriesMeta}
            index={i}
          />
        </li>
      );
    });

    var result = (
      <ul className="uk-grid-small uk-margin uk-child-width-1-1 upsell-categories-wrapper" data-uk-grid>
        {options}

        <li>
          <div className="uk-card uk-card-default uk-card-small uk-card-body uk-text-center upsell-categories-add" onClick={handleAddCategory}>
            <span className="uk-text-center" uk-icon="icon: plus"></span>
          </div>
        </li>
      </ul>
    );


    return result;
  }



  return (
    <>
      {renderCategories()}
    </>
  );
}

export default Categories;
