import AbstractApi from "../AbstractApi";

class PixelsTokensApi extends AbstractApi  {

  // PixelsTokens
  createMethod = 'dashboard/facebook/pixels_tokens/create';
  getFilterMethod = 'dashboard/facebook/pixels_tokens/get';
  getMethod = 'dashboard/facebook/pixels_tokens/{:id}/get';
  updateMethod = 'dashboard/facebook/pixels_tokens/{:id}/update';
  deleteMethod = 'dashboard/facebook/pixels_tokens/{:id}/delete';
  metaMethod = 'dashboard/facebook/pixels_tokens/meta';

  /*--------------------------------------------------------------------------*/
  /* PixelsTokens */

  async create(pixel, accessToken, comment = false) {

    this.requestMethod = this.createMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('pixel', pixel);
    this.requestData.append('access_token', accessToken);

    if(comment != false){
      this.requestData.append('comment', comment);
    }

    return await this.request();
  }


  async get(id = false, filterOrderBy = 'pixel', filterOrder = 'asc', filterSearch = false, filterDashboardUser = false, paginationPage = 1, paginationLimit = 50) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;

      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      if(filterDashboardUser){
        this.requestData.append('filter[dashboard_user]', filterDashboardUser);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async update(id, pixel = false, accessToken = false, comment = false) {

    this.requestMethod = this.updateMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(pixel != false){
      this.requestData.append('pixel', pixel);
    }

    if(accessToken != false){
      this.requestData.append('access_token', accessToken);
    }

    if(comment != false){
      this.requestData.append('comment', comment);
    }

    return await this.request();
  }


  async delete(id) {

    this.requestMethod = this.deleteMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }


  async meta() {

    this.requestMethod = this.metaMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }

}

export default PixelsTokensApi;
