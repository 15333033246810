import { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";

import { RoleContext } from '../../components/app/contexts/RoleContext';

import filterOrderByIcon from '../../assets/img/filter-left.svg';
import filterOrderIcon from '../../assets/img/arrow-down-up.svg';
import filterTypeIcon from '../../assets/img/ui-checks-grid.svg';
import filterCrmBuyerIdIcon from '../../assets/img/people-fill.svg';
import filterUpsellsCategoryIdIcon from '../../assets/img/bookmark-star.svg';


function SitesTableFilter(props){

  const role = useContext(RoleContext);

  const [filterOrderBy, setFilterOrderBy] = props.filterOrderBy;
  const [filterOrder, setFilterOrder] = props.filterOrder;
  const [filterSearch, setFilterSearch] = props.filterSearch;
  const [filterType, setFilterType] = props.filterType;
  const [filterCrmBuyerId, setFilterCrmBuyerId] = props.filterCrmBuyerId;
  const [filterUpsellsCategoryId, setFilterUpsellsCategoryId] = props.filterUpsellsCategoryId;
  const [filterPage, setFilterPage] = props.filterPage;

  const getData = props.getData;
  const typesMap = props.typesMap;
  const typesMeta = props.typesMeta;
  const crmBuyerIdsMeta = props.crmBuyerIdsMeta;
  const upsellsCategoriesMeta = props.upsellsCategoriesMeta;

  const handleFilterChange = async (e) => {
    e.preventDefault();

    var {id, value} = e.target;

    if(id == 'filterOrderBy'){
      await setFilterPage(1);
      await setFilterOrderBy(value);
    }

    if(id == 'filterOrder'){
      await setFilterPage(1);
      await setFilterOrder(value);
    }

    if(id == 'filterSearch'){
      await setFilterPage(1);
      await setFilterSearch(value);
    }

    if(id == 'filterType'){
      await setFilterPage(1);
      await setFilterType(value);
    }

    if(id == 'filterCrmBuyerId'){
      await setFilterPage(1);
      await setFilterCrmBuyerId(value);
    }

    if(id == 'filterUpsellsCategoryId'){
      await setFilterPage(1);
      await setFilterUpsellsCategoryId(value);
    }

  }


  function renderTypeOptions() {
    var options = [];
    options.push(
      <option key="type-default" value="">Тип сайта</option>
    );

    Object.keys(typesMeta).forEach((item, i) => {
      options.push(
        <option key={"type-" + i} value={item}>{typesMap[item]}</option>
      );
    });

    return options;
  }


  function renderCrmBuyerIdOptions() {
    var options = [];
    options.push(
      <option key="crm-buyer-id-default" value="">Buyer ID</option>
    );

    Object.keys(crmBuyerIdsMeta).forEach((item, i) => {

      if(!item){
        return;
      }

      options.push(
        <option key={"crm-buyer-id-" + i} value={item}>{item}</option>
      );
    });

    return options;
  }


  function renderUpsellsCategoriesOptions() {
    var options = [];
    options.push(
      <option key="upsells-category-id-default" value="">Категория</option>
    );

    upsellsCategoriesMeta.forEach((item, i) => {
      options.push(
        <option key={"upsells-category-id-" + i} value={item.id}>{item.name}</option>
      );
    });

    return options;
  }


  return (
    <div className="uk-flex-right uk-grid-collapse" data-uk-grid>

      <div id="filterSearchDiv" style={{maxWidth: '120px'}}>
        <form className="uk-search uk-search-default">
          <span data-uk-search-icon></span>
          <input
            className="uk-search-input"
            type="search"
            id="filterSearch"
            name="filterSearch"
            placeholder="Поиск"
            value={filterSearch}
            onChange={handleFilterChange}
          />
        </form>
      </div>

      <div id="filterTypeDiv" style={{maxWidth: '7.2rem'}}>
        <picture>
          <img src={filterTypeIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterType"
          name="filterType"
          value={filterType}
          onChange={handleFilterChange}
        >
          {renderTypeOptions()}
        </select>
      </div>

      {(role == 'owner' || role == 'admin') && (

        <div id="filterCrmBuyerIdDiv" style={{maxWidth: '7rem'}}>
          <picture>
            <img src={filterCrmBuyerIdIcon} />
          </picture>
          <select
            className="uk-select"
            id="filterCrmBuyerId"
            name="filterCrmBuyerId"
            value={filterCrmBuyerId}
            onChange={handleFilterChange}
          >
            {renderCrmBuyerIdOptions()}
          </select>
        </div>
      )}

      <div id="filterUpsellsCategoryIdDiv" style={{maxWidth: '7.4rem'}}>
        <picture>
          <img src={filterUpsellsCategoryIdIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterUpsellsCategoryId"
          name="filterUpsellsCategoryId"
          value={filterUpsellsCategoryId}
          onChange={handleFilterChange}
        >
          {renderUpsellsCategoriesOptions()}
        </select>
      </div>

      <div id="filterOrderByDiv" style={{maxWidth: '7.2rem'}}>
        <picture>
          <img src={filterOrderByIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrderBy"
          name="filterOrderBy"
          value={filterOrderBy}
          onChange={handleFilterChange}
        >
          <option value="id">ID</option>
          <option value="name">Идентификатор</option>
          <option value="internal_name">Название оффера</option>
          <option value="url">Ссылка на сайт</option>
          <option value="default_price">Цена</option>
          <option value="default_quantity">Количество</option>
          <option value="default_external_id">Внешний ID</option>
          <option value="type">Тип</option>
          <option value="crm_shop">CRM Shop</option>
          <option value="crm_buyer_id">Buyer ID</option>
          <option value="gift_sum">Стоимость для подарка</option>
          <option value="gift_id">Подарок</option>
          <option value="created">Создан</option>
          <option value="modified">Обновлен</option>
        </select>
      </div>

      <div id="filterOrderDiv" style={{maxWidth: '7.2rem'}}>
        <picture>
          <img src={filterOrderIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrder"
          name="filterOrder"
          value={filterOrder}
          onChange={handleFilterChange}
        >
          <option value="desc">По убыванию</option>
          <option value="asc">По возрастанию</option>
        </select>
      </div>

    </div>
  );
}

export default SitesTableFilter;
