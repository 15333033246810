import AbstractApi from "./AbstractApi";

class UpsellsApi extends AbstractApi  {

  // Upsells
  createMethod = 'dashboard/upsells/create';
  getFilterMethod = 'dashboard/upsells/get';
  getMethod = 'dashboard/upsells/{:id}/get';
  updateMethod = 'dashboard/upsells/{:id}/update';
  deleteMethod = 'dashboard/upsells/{:id}/delete';

  /*--------------------------------------------------------------------------*/
  /* Upsells */

  async create(name, status, upsellsCategories, priority, price, quantity, externalId, title = false, priceOld = false, discount = false, priceNew = false, description = false, stockText = false, stock = false) {

    this.requestMethod = this.createMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('name', name);
    this.requestData.append('status', status);

    upsellsCategories.forEach((item, i) => {
      this.requestData.append(String('upsells_categories[').concat(i).concat(']'), item);
    });

    this.requestData.append('priority', priority);

    this.requestData.append('price', price);
    this.requestData.append('quantity', quantity);
    this.requestData.append('external_id', externalId);

    if(title != false){
      this.requestData.append('blocks[title]', title);
    }

    if(priceOld != false){
      this.requestData.append('blocks[price_old]', priceOld);
    }

    if(discount != false){
      this.requestData.append('blocks[discount]', discount);
    }

    if(priceNew != false){
      this.requestData.append('blocks[price_new]', priceNew);
    }

    if(description != false){
      this.requestData.append('blocks[description]', description);
    }

    if(stockText != false){
      this.requestData.append('blocks[stock_text]', stockText);
    }

    if(stock != false){
      this.requestData.append('blocks[stock]', stock);
    }

    return await this.request();
  }


  async get(id = false, filterOrderBy = 'name', filterOrder = 'asc', filterSearch = false, filterStatus = false, filterUpsellsCategoryId = false, paginationPage = 1, paginationLimit = 50) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;

      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      if(parseInt(filterStatus) == 0 || parseInt(filterStatus) == 1){
        this.requestData.append('filter[status]', filterStatus);
      }

      if(filterUpsellsCategoryId > 0){
        this.requestData.append('filter[upsells_category_id]', filterUpsellsCategoryId);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async update(id, name, status, upsellsCategories, priority, price, quantity, externalId, title = false, priceOld = false, discount = false, priceNew = false, description = false, stockText = false, stock = false) {

    this.requestMethod = this.updateMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('name', name);
    this.requestData.append('status', status);

    upsellsCategories.forEach((item, i) => {
      this.requestData.append(String('upsells_categories[').concat(i).concat(']'), item);
    });

    this.requestData.append('priority', priority);

    this.requestData.append('price', price);
    this.requestData.append('quantity', quantity);
    this.requestData.append('external_id', externalId);

    if(title != false){
      this.requestData.append('blocks[title]', title);
    }

    if(priceOld != false){
      this.requestData.append('blocks[price_old]', priceOld);
    }

    if(discount != false){
      this.requestData.append('blocks[discount]', discount);
    }

    if(priceNew != false){
      this.requestData.append('blocks[price_new]', priceNew);
    }

    if(description != false){
      this.requestData.append('blocks[description]', description);
    }

    if(stockText != false){
      this.requestData.append('blocks[stock_text]', stockText);
    }

    if(stock != false){
      this.requestData.append('blocks[stock]', stock);
    }

    return await this.request();
  }


  async delete(id) {

    this.requestMethod = this.deleteMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }

}

export default UpsellsApi;
