import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { NotificationsContext } from '../../../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../../../components/app/contexts/ApiContext';


function PixelsTokenDeleteModalScreen(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const data = props.data;
  const selected = props.selected;

  const [deleteModal, setDeleteModal] = props.deleteModal;
  const getData = props.getData;

  var modalStateClass = ' uk-open';


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await selected.forEach(async (item, i) => {
      const response = await api.facebook.pixelsTokens.delete(
        item.value
      );
    });

    notifications.facebook.pixelsTokens.notification('facebook_pixels_token_deleted')

    setTimeout(async function(){
      await getData();
      await handleCancel(e);
    }, 2500);

    return false;
  }


  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDeleteModal([]);
    return false;
  }



  return (

    <div id="messages-modal" className={"uk-modal" + modalStateClass} data-uk-modal style={{display: 'block'}}>
      <div className="uk-modal-dialog">
        <form id="hierModalForm" className="uk-form-stacked" onSubmit={handleSubmit}>
          <button className="uk-modal-close-default" data-uk-close type="button" onClick={handleCancel}></button>
          <div className="uk-modal-header">
            <h4 className="uk-modal-title">Пожалуйста, подтвердите удаление выбранных пар Facebook пиксель/токен</h4>
          </div>
          <div className="uk-modal-footer uk-text-right">
            <button className="uk-button uk-button-default" type="button" onClick={handleCancel}>Отмена</button>
            <button className="uk-button uk-button-danger custom-button-danger" type="submit">Удалить</button>
          </div>
        </form>
      </div>
    </div>

  )
}

export default PixelsTokenDeleteModalScreen;
