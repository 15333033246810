import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { renderDateTime } from '../../components/app/Functions';
import UserModalScreen from './modals/UserModalScreen';
import UserDeleteModalScreen from './modals/UserDeleteModalScreen';

import UsersTableFilter from './UsersTableFilter';
import TablePagination from '../../components/common/tables/TablePagination';
import UsersTablePosition from './UsersTablePosition';

import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../components/app/contexts/ApiContext';

const rolesMap = {
  'owner': 'Владелец',
  'admin': 'Администратор',
  'user': 'Пользователь'
};

function UsersTable(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const [data, setData] = useState([]);

  const [filterOrderBy, setFilterOrderBy] = useState('login');
  const [filterOrder, setFilterOrder] = useState('asc');
  const [filterSearch, setFilterSearch] = useState('');
  const [filterRole, setFilterRole] = useState('');
  const [filterLimit, setFilterLimit] = useState(50);
  const [filterPage, setFilterPage] = useState(1);
  const [filterTotalPages, setFilterTotalPages] = useState(1);

  const [modal, setModal] = useState([]);
  const [deleteModal, setDeleteModal] = useState([]);

  const [roles, setRoles] = useState([]);

  const params = useParams();

  async function getData(){

    var dataFeed = await api.users.get(false, filterOrderBy, filterOrder, filterSearch, filterRole, filterPage, filterLimit);

    if(dataFeed.status === true){
      await setData(dataFeed.data);
      if(dataFeed.pagination.page){
        // await setFilterPage(dataFeed.pagination.page);
        await setFilterTotalPages(dataFeed.pagination.total);
      }
    }
  }


  async function getMeta(){
    var dataFeed = await api.users.meta();

    if(dataFeed.status == true){
      setRoles(dataFeed.data.role);
    }
  }


  useEffect( () => {
    getMeta();
  }, []);

  useEffect( () => {
    getData();
  }, [filterOrderBy, filterOrder, filterSearch, filterRole, filterPage, filterLimit]);


  const openModal = (e) => {
    e.preventDefault();

    var itemId = e.currentTarget.getAttribute('data-user-id');

    setModal(
      <UserModalScreen
        userId={itemId}
        users={data}
        modal={[modal, setModal]}
        getData={getData}
        rolesMap={rolesMap}
      />
    );


  }



  function renderTableRows(){

    var result = [];

    data.forEach((item, i) => {

      if(item.role != 'owner'){
        result.push(
          <UsersTablePosition
            key={String('user-').concat(i)}
            index={i}
            data={data}
            item={item}
            openModal={openModal}
            handleSingleSelection={handleSingleSelection}
            getData={getData}
            filterOrderBy={filterOrderBy}
            filterOrder={filterOrder}
            rolesMap={rolesMap}
          />
        );
      }

    });


    if(result.length == 0){
      result.push(
        <tr key="no-entries-found" className="uk-text-lead uk-text-center uk-margin">
          <td colSpan="8">
            Нет данных
          </td>
        </tr>
      )
    }

    return result;
  }


  const openDeleteModal = async (e) => {

    var selected = document.querySelectorAll('[name="deleteItems[]"]:checked');

    setDeleteModal(
      <UserDeleteModalScreen
        users={data}
        usersSelected={selected}
        deleteModal={[deleteModal, setDeleteModal]}
        getData={getData}
      />
    );
  }


  const handleSingleSelection = async (e) => {

    var elem = e.currentTarget;
    var cond = elem.checked;
    var deleteAllCheckbox = document.querySelector('table.custom-table thead input[name="deleteAll"]');

    var checkboxes = document.querySelectorAll('table.custom-table tbody input[name="deleteItems[]"]');
    var checkboxesChecked = document.querySelectorAll('table.custom-table tbody input[name="deleteItems[]"]:checked');


    var deleteAllCond = false;
    if(deleteAllCheckbox){
      deleteAllCond = deleteAllCheckbox.checked;
    }

    if(cond == false && deleteAllCond == true){
      deleteAllCheckbox.checked = false;
    }

    if(checkboxes.length == checkboxesChecked.length){
      deleteAllCheckbox.checked = true;
    }

  }


  const handleBulkSelection = async (e) => {

    var elem = e.currentTarget;
    var cond = elem.checked;

    var checkboxes = document.querySelectorAll('table.custom-table tbody input[name="deleteItems[]"]');

    checkboxes.forEach((item, i) => {
      item.checked = cond;
    });

  }


  return (

    <>
      <div className="uk-grid-collapse" data-uk-grid>

        <div className="uk-width-1-4">
          <div className="uk-text-left">
            <button className="uk-button uk-button-primary custom-button-success" onClick={openModal} data-user-id={-1}>Добавить</button>
            <button className="uk-button uk-button-danger custom-button-danger" onClick={openDeleteModal}>Удалить</button>
          </div>
        </div>

        <div className="uk-width-3-4">

          <UsersTableFilter
            filterOrderBy={[filterOrderBy, setFilterOrderBy]}
            filterOrder={[filterOrder, setFilterOrder]}
            filterSearch={[filterSearch, setFilterSearch]}
            filterRole={[filterRole, setFilterRole]}
            filterPage={[filterPage, setFilterPage]}
            getData={getData}
            roles={roles}
            rolesMap={rolesMap}
          />

        </div>

      </div>

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />

      <table className="uk-table uk-table-divider uk-table-middle custom-table users-table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" name="deleteAll" value={true} onChange={handleBulkSelection} />
            </th>
            {/*<th></th>*/}
            <th>ID</th>
            <th>Логин</th>
            <th>Buyer ID</th>
            <th>Роль</th>
            <th>Создан</th>
            <th>Обновлен</th>
            <th className="uk-text-center" data-uk-tooltip="Редактировать">
              <span className="uk-icon" data-uk-icon="pencil"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>

      {modal}
      {deleteModal}

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />

    </>

  )
}

export default UsersTable;
