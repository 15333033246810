import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import filterOrderByIcon from '../../assets/img/filter-left.svg';
import filterOrderIcon from '../../assets/img/arrow-down-up.svg';
import filterStatusIcon from '../../assets/img/check2-square.svg';
import filterUpsellsCategoryIdIcon from '../../assets/img/bookmark-star.svg';

function UpsellsTableFilter(props){

  const [filterOrderBy, setFilterOrderBy] = props.filterOrderBy;
  const [filterOrder, setFilterOrder] = props.filterOrder;
  const [filterSearch, setFilterSearch] = props.filterSearch;
  const [filterStatus, setFilterStatus] = props.filterStatus;
  const [filterUpsellsCategoryId, setFilterUpsellsCategoryId] = props.filterUpsellsCategoryId;
  const [filterPage, setFilterPage] = props.filterPage;

  const getData = props.getData;
  const upsellsCategoriesMeta = props.upsellsCategoriesMeta;

  const handleFilterChange = async (e) => {
    e.preventDefault();

    var {id, value} = e.target;

    if(id === 'filterOrderBy'){
      await setFilterPage(1);
      await setFilterOrderBy(value);
    }

    if(id === 'filterOrder'){
      await setFilterPage(1);
      await setFilterOrder(value);
    }

    if(id === 'filterSearch'){
      await setFilterPage(1);
      await setFilterSearch(value);
    }

    if(id === 'filterStatus'){
      await setFilterPage(1);
      await setFilterStatus(value);
    }

    if(id === 'filterUpsellsCategoryId'){
      await setFilterPage(1);
      await setFilterUpsellsCategoryId(value);
    }

  }


  function renderUpsellsCategoriesOptions() {
    var options = [];
    options.push(
      <option key="upsells-category-id-default" value="">Категория</option>
    );

    upsellsCategoriesMeta.forEach((item, i) => {
      options.push(
        <option key={"upsells-category-id-" + i} value={item.id}>{item.name}</option>
      );
    });

    return options;
  }


  return (
    <div className="uk-flex-right uk-grid-collapse" data-uk-grid>

      <div id="filterSearchDiv" style={{maxWidth: '180px'}}>
        <form className="uk-search uk-search-default">
          <span data-uk-search-icon></span>
          <input
            className="uk-search-input"
            type="search"
            id="filterSearch"
            name="filterSearch"
            placeholder="Поиск"
            value={filterSearch}
            onChange={handleFilterChange}
          />
        </form>
      </div>

      <div id="filterStatusDiv" style={{maxWidth: '6.2rem'}}>
        <picture>
          <img src={filterStatusIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterStatus"
          name="filterStatus"
          value={filterStatus}
          onChange={handleFilterChange}
        >
          <option value="">Статус</option>
          <option value="0">Неактивен</option>
          <option value="1">Активен</option>
        </select>
      </div>

      <div id="filterUpsellsCategoryIdDiv" style={{maxWidth: '7.6rem'}}>
        <picture>
          <img src={filterUpsellsCategoryIdIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterUpsellsCategoryId"
          name="filterUpsellsCategoryId"
          value={filterUpsellsCategoryId}
          onChange={handleFilterChange}
        >
          {renderUpsellsCategoriesOptions()}
        </select>
      </div>

      <div id="filterOrderByDiv" style={{maxWidth: '6.8rem'}}>
        <picture>
          <img src={filterOrderByIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrderBy"
          name="filterOrderby"
          value={filterOrderBy}
          onChange={handleFilterChange}
        >
          <option value="id">ID</option>
          <option value="name">Название</option>
          <option value="status">Статус</option>
          <option value="priority">Приоритет</option>
          <option value="price">Цена</option>
          <option value="quantity">Количество</option>
          <option value="external_id">Внешний ID</option>
          <option value="created">Создан</option>
          <option value="modified">Обновлен</option>
        </select>
      </div>


      <div id="filterOrderDiv" style={{maxWidth: '7.6rem'}}>
        <picture>
          <img src={filterOrderIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrder"
          name="filterOrder"
          value={filterOrder}
          onChange={handleFilterChange}
        >
          <option value="desc">По убыванию</option>
          <option value="asc">По возрастанию</option>
        </select>
      </div>

    </div>
  );
}

export default UpsellsTableFilter;
