import AbstractNotifications from './AbstractNotifications';

class SitesCataloguesNotifications extends AbstractNotifications {
  messages = {
    'site_catalogue_created': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Сайт-каталог создан'
    },
    'site_catalogue_updated': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },
    'site_catalogue_deleted': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },

    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    },
    'existing_site_name': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Сайт-каталог с таким идентификатором уже существует'
    },

    'invalid_catalogue_order_total_min': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Минимальная сумма заказа должна быть > 0'
    },

  }
}

export default SitesCataloguesNotifications;
