import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import filterOrderByIcon from '../../assets/img/filter-left.svg';
import filterOrderIcon from '../../assets/img/arrow-down-up.svg';
import filterSectionIcon from '../../assets/img/signpost.svg';
import filterUserIdIcon from '../../assets/img/people-fill.svg';

import {renderDateTime, renderDateTimestamp, renderDateTimestampDate, roundFloat} from '../../components/app/Functions';


function LogsTableFilter(props){

  const [filterOrderBy, setFilterOrderBy] = props.filterOrderBy;
  const [filterOrder, setFilterOrder] = props.filterOrder;
  const [filterSearch, setFilterSearch] = props.filterSearch;
  const [filterSection, setFilterSection] = props.filterSection;
  const [filterUserId, setFilterUserId] = props.filterUserId;
  const [filterCreated, setFilterCreated] = props.filterCreated;
  const [filterCreatedDate, setFilterCreatedDate] = useState('');
  const [filterPage, setFilterPage] = props.filterPage;

  const sectionsMeta = props.sectionsMeta;
  const sectionsMap = props.sectionsMap;
  const userIdsMeta = props.userIdsMeta;

  const getData = props.getData;

  const handleFilterChange = async (e) => {
    e.preventDefault();

    var {id, value} = e.target;

    if(id == 'filterOrderBy'){
      await setFilterPage(1);
      await setFilterOrderBy(value);
    }

    if(id == 'filterOrder'){
      await setFilterPage(1);
      await setFilterOrder(value);
    }

    if(id == 'filterSearch'){
      await setFilterPage(1);
      await setFilterSearch(value);
    }

    if(id == 'filterSection'){
      await setFilterPage(1);
      await setFilterSection(value);
    }

    if(id == 'filterUserId'){
      await setFilterPage(1);
      await setFilterUserId(value);
    }

    if(id == 'filterCreated'){
      await setFilterPage(1);
      await setFilterCreatedDate(value);
      await setFilterCreated(renderDateTimestampDate(value));
    }

  }


  function renderSectionsMetaList(){

    var options = [];

    options.push(
      <option key="section-init" value="">-- Раздел --</option>
    );
    Object.keys(sectionsMeta).forEach((item, i) => {
      if(!item){
        return;
      }

      options.push(
        <option key={"section-" + i} value={item}>{sectionsMap[item]}</option>
      );
    });

    return options;
  }


  function renderUserIdsMetaList(){

    var options = [];

    options.push(
      <option key="user-id-init" value="">-- Пользователь --</option>
    );
    Object.keys(userIdsMeta).forEach((item, i) => {

      if(!item){
        return;
      }

      options.push(
        <option key={"user-id-" + i} value={item}>{item}</option>
      );
    });

    return options;
  }


  return (
    <div className="uk-flex-right uk-grid-collapse logs-table-filter" data-uk-grid>

      <div id="filterSearchDiv">
        <form className="uk-search uk-search-default">
          <span data-uk-search-icon></span>
          <input
            className="uk-search-input"
            type="search"
            id="filterSearch"
            name="filterSearch"
            placeholder="Поиск"
            value={filterSearch}
            onChange={handleFilterChange}
          />
        </form>
      </div>


      <div id="filterCreatedDiv">
        <input
          className="uk-input"
          type="date"
          id="filterCreated"
          name="filterCreated"
          placeholder="Дата"
          value={filterCreatedDate}
          onChange={handleFilterChange}
        />
      </div>


      <div id="filterSectionDiv">
        <picture>
          <img src={filterSectionIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterSection"
          name="filterSection"
          value={filterSection}
          onChange={handleFilterChange}
        >
          {renderSectionsMetaList()}
        </select>
      </div>


      <div id="filterUserIdDiv">
        <picture>
          <img src={filterUserIdIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterUserId"
          name="filterUserId"
          value={filterUserId}
          onChange={handleFilterChange}
        >
          {renderUserIdsMetaList()}
        </select>
      </div>


      <div id="filterOrderByDiv">
        <picture>
          <img src={filterOrderByIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrderBy"
          name="filterOrderby"
          value={filterOrderBy}
          onChange={handleFilterChange}
        >
          <option value="created">Создан</option>
          <option value="user_id">Пользователь</option>
          <option value="section">Раздел</option>
          <option value="type">Тип</option>
          <option value="params">Параметры</option>
          <option value="message">Сообщение</option>
        </select>
      </div>


      <div id="filterOrderDiv">
        <picture>
          <img src={filterOrderIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrder"
          name="filterOrder"
          value={filterOrder}
          onChange={handleFilterChange}
        >
          <option value="desc">По убыванию</option>
          <option value="asc">По возрастанию</option>
        </select>
      </div>

    </div>
  );
}

export default LogsTableFilter;
