import UIkit from 'uikit';

class AbstractNotifications {

  defaultTimeout = 1000;
  defaultStatus = 'danger'; // danger | success
  defaultPosition = 'top-center';

  templates = {
    'success': "<span uk-icon='icon: check'></span> ",
    'danger': "<span uk-icon='icon: close'></span> "
  };

  defaultMessages = {
    'error': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Произошла ошибка'
    }
  };

  messages = {}


  async notification(code, extra = false) {

    const messageEntity = code in this.messages ? this.messages[code] : this.defaultMessages['error'];

    const timeout = 'timeout' in messageEntity ? messageEntity['timeout'] : this.defaultTimeout;
    const status = 'status' in messageEntity ? messageEntity['status'] : this.defaultStatus;
    const position = 'position' in messageEntity ? messageEntity['position'] : this.defaultPosition;
    const message = 'message' in messageEntity ? messageEntity['message'] : this.defaultMessages['error']['message'];

    var template = String(this.templates[status]).concat(message);

    if(extra != false){
      template = template.concat(extra);
    }

    setTimeout(async function(){
      UIkit.notification(template, {status: status, pos: position} );
    }, timeout);
  }
}

export default AbstractNotifications;
