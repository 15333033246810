import { useState } from 'react';

export default function useAuthLogin() {
  const getLogin = () => {
    var data = localStorage.getItem('adminAuthLogin');
    if(data == 'null'){
      data = null;
    }
    return data;
  };

  const [authLogin, setAuthLogin] = useState(getLogin());

  const setLogin = login => {
    localStorage.setItem('adminAuthLogin', login);
    setAuthLogin(login);
  };

  return {
    setAuthLogin: setLogin,
    authLogin
  }
}
