import AbstractNotifications from '../AbstractNotifications';

class PixelsTokensNotifications extends AbstractNotifications {
  messages = {
    'facebook_pixels_token_created': {
      'timeout': 1000,
      'status': 'success',
      'message': 'FB пиксель/токен создан'
    },
    'facebook_pixels_token_updated': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },
    'facebook_pixels_token_deleted': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },

    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    },
    'existing_facebook_pixel': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Такой FB пиксель уже существует'
    },
    'invalid_server_event': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Ошибка при отправке тестового события'
    }
  }
}

export default PixelsTokensNotifications;
