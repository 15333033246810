import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';

import LogsTable from '../../components/logs/LogsTable';

function Logs() {

  return (
    <div className="uk-container">

      <div data-uk-grid>

        <div className="uk-width-3-4">
          <Breadcrumbs>
            <Breadcrumb><NavLink to="/">Панель администратора</NavLink></Breadcrumb>
            <Breadcrumb><span>Логи</span></Breadcrumb>
          </Breadcrumbs>
        </div>

      </div>

      <h1 className="uk-heading-line uk-text-center">
        <span>Логи</span>
      </h1>

      <h1 className="uk-heading-divider"></h1>

      <LogsTable />

    </div>
  )
}

export default Logs;
