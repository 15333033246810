import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import filterOrderByIcon from '../../../assets/img/filter-left.svg';
import filterOrderIcon from '../../../assets/img/arrow-down-up.svg';

function CategoriesTableFilter(props){

  const [filterOrderBy, setFilterOrderBy] = props.filterOrderBy;
  const [filterOrder, setFilterOrder] = props.filterOrder;
  const [filterSearch, setFilterSearch] = props.filterSearch;
  const [filterPage, setFilterPage] = props.filterPage;

  const getData = props.getData;


  const handleFilterChange = async (e) => {
    e.preventDefault();

    var {id, value} = e.target;

    if(id == 'filterOrderBy'){
      await setFilterPage(1);
      await setFilterOrderBy(value);
    }

    if(id == 'filterOrder'){
      await setFilterPage(1);
      await setFilterOrder(value);
    }

    if(id == 'filterSearch'){
      await setFilterPage(1);
      await setFilterSearch(value);
    }

  }


  return (
    <div className="uk-flex-right uk-grid-collapse" data-uk-grid>

      <div id="filterSearchDiv">
        <form className="uk-search uk-search-default">
          <span data-uk-search-icon></span>
          <input
            className="uk-search-input"
            type="search"
            id="filterSearch"
            name="filterSearch"
            placeholder="Поиск"
            value={filterSearch}
            onChange={handleFilterChange}
          />
        </form>
      </div>

      <div id="filterOrderByDiv">
        <picture>
          <img src={filterOrderByIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrderBy"
          name="filterOrderby"
          value={filterOrderBy}
          onChange={handleFilterChange}
        >
          <option value="id">ID</option>
          <option value="name">Название</option>
          <option value="created">Создан</option>
          <option value="modified">Обновлен</option>
        </select>
      </div>


      <div id="filterOrderDiv">
        <picture>
          <img src={filterOrderIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrder"
          name="filterOrder"
          value={filterOrder}
          onChange={handleFilterChange}
        >
          <option value="desc">По убыванию</option>
          <option value="asc">По возрастанию</option>
        </select>
      </div>

    </div>
  );
}

export default CategoriesTableFilter;
