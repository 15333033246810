import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../../../../components/app/Functions';

import Content from './Content';

const roundPrecision = 100000;

function Option(props){

  const type = props.type;
  const index = props.index;
  const subIndex = props.subIndex;

  const [formula, setFormula] = props.formula;
  const [opened, setOpened] = props.opened;

  const item = formula[index].options[subIndex];


  const handleDelete = async (e) => {

    var formulaNew = [...formula];
    formulaNew[index].options = formulaNew[index].options.filter((elem, i) => {
      return i !== subIndex;
    });

    setFormula(formulaNew);
  }


  const handleArrowUp = (e) => {
    if(subIndex === 0){
      return false;
    }

    var formulaNew = [...formula];
    var temp = formulaNew[index].options[subIndex - 1];
    formulaNew[index].options[subIndex - 1] = formulaNew[index].options[subIndex];
    formulaNew[index].options[subIndex] = temp;

    setFormula(formulaNew);
  }


  const handleArrowDown = (e) => {
    if( (subIndex + 1) === formula[index].options.length){
      return false;
    }

    var formulaNew = [...formula];
    var temp = formulaNew[index].options[subIndex + 1];
    formulaNew[index].options[subIndex + 1] = formulaNew[index].options[subIndex];
    formulaNew[index].options[subIndex] = temp;

    setFormula(formulaNew);
  }


  function renderArrows(){

    var arrowUp = null;
    var arrowDown = null;

    if(subIndex !== 0){
      arrowUp = (
        <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-up" onClick={handleArrowUp}></span>
      );
    }

    if( (subIndex + 1) !== formula[index].options.length){
      arrowDown = (
        <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-down" onClick={handleArrowDown}></span>
      );
    }

    return (
      <div className="arrows">
        {arrowUp}
        {arrowDown}
      </div>
    );
  }


  function renderOption(){

    const title = item.external_id != '' ? (<><u>{item.external_id}</u> ✕ {item.quantity} = <b>{roundFloat(item.price * item.quantity, roundPrecision)}</b></>) : '';

    return (
      <span className="uk-width-1-1 uk-text-left">
        Позиция: {title}
      </span>
    );
  }


  const handleOpened = async (e) => {
    var newOpened = [...opened];

    var optionId = type + '-' + index + '-' + subIndex;

    if(newOpened.indexOf(optionId) !== -1){
      // Removes the ID from opened
      newOpened = newOpened.filter((item, i) => {
        return item != optionId;
      });
    } else {
      // Adds the ID to opened
      newOpened.push(optionId);
    }

    setOpened(newOpened);
  };


  function renderContent(){

    var content = null;
    var optionId = type + '-' + index + '-' + subIndex;

    var dataOpened = opened.indexOf(optionId) !== -1 ? 1 : 0;

    if(dataOpened){
      content = (
        <Content
          type={type}
          index={index}
          subIndex={subIndex}
          formula={[formula, setFormula]}
        />
      );
    }

    return (
      <div className="uk-card-footer site-variation-option-content" data-opened={dataOpened}>
        {content}
      </div>
    );

  }


  return (
    <div className="uk-card uk-card-default uk-card-small">

      <div className="uk-card-body">
        <div className="uk-flex uk-flex-middle">

          <div className="uk-width-1-6 uk-text-center">
            {renderArrows()}
          </div>

          <div className="uk-width-4-6 uk-margin-right" onClick={handleOpened}>
            <h4 className="uk-margin-remove site-variation-option-title uk-grid-small" data-uk-grid>{renderOption()}</h4>
          </div>

          <div className="uk-width-1-6 uk-text-center">
            <span className="uk-text-center trash-button" uk-icon="icon: trash" onClick={handleDelete}></span>
          </div>

        </div>
      </div>

      {renderContent()}

    </div>
  );
}

export default Option;
