import { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

const headings = {
  'create': 'Создать вложение товара допродаж',
  'update': 'Редактировать вложение товара допродаж'
};

function AttachmentsModalScreen(props) {

  const [data, setData] = props.data;
  const [modal, setModal] = props.modal;
  const index = props.index;

  var sortTemp = data.length;
  var mediaTemp = null;
  var commentTemp = '';
  if(index >= 0){
    sortTemp = index;
    mediaTemp = data[index].media;
    commentTemp = data[index].comment;
  }

  const [comment, setComment] = useState(commentTemp);
  const [sort, setSort] = useState(sortTemp);
  const [media, setMedia] = useState(mediaTemp);
  const [file, setFile] = useState(false);

  const heading = props.index >= 0 ? headings['update'] : headings['create'];

  var modalStateClass = ' uk-open';


  const handleInputChange = (e) => {
    var {id, value} = e.target;

    if(id == 'comment'){
      setComment(value);
    }

    // if(id == 'sort'){
    //   setSort(value);
    // }

    if(id === 'fileNew' && e.target.files){
      setFile(e.target.files[0]);
      setMedia(URL.createObjectURL(e.target.files[0]));
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(index >= 0){

      var attachmentsNew = [...data];

      attachmentsNew[index].comment = comment;
      attachmentsNew[index].media = media;

      if(file){
        attachmentsNew[index].file = file;
      }

      setData(attachmentsNew);

    } else {

      var attachmentsNew = [...data];

      attachmentsNew.push({
        id: -1,
        sort: sort,
        media: media,
        file: file,
        comment: comment
      });

      setData(attachmentsNew);

    }

    setTimeout(async function(){
      await handleCancel(e);
    }, 500);

    return false;

  }


  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setModal([]);
    return false;

  }


  return (

  <div id="messages-modal" className={"uk-modal" + modalStateClass} data-uk-modal style={{display: 'block'}}>
    <div className="uk-modal-dialog">
      <form id="hierModalForm" className="uk-form-stacked" onSubmit={handleSubmit}>
        <button className="uk-modal-close-default" data-uk-close type="button" onClick={handleCancel}></button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">{heading}</h2>
        </div>
        <div className="uk-modal-body">

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="fileNew">Вложение:</label>
            <div className="uk-form-controls">
              <input
                id="fileNew"
                name="fileNew"
                type="file"
                accept="image/*"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="comment">Описание (атрибут <u>alt</u>):</label>
            <div className="uk-form-controls">
              <textarea
                id="comment"
                name="comment"
                value={comment}
                onChange={handleInputChange}
                className="uk-textarea"
              />
            </div>
          </div>

          {/*<div className="uk-margin">
            <label className="uk-form-label" htmlFor="sort">Позиция:</label>
            <div className="uk-form-controls">
              <input
                id="sort"
                name="sort"
                value={sort}
                onChange={handleInputChange}
                className="uk-input"
                type="number"
                min="0"
                step="1"
              />
            </div>
          </div>*/}

        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default" type="button" onClick={handleCancel}>Отмена</button>
          <button className="uk-button uk-button-primary custom-button-success" type="submit">Сохранить</button>
        </div>
      </form>
    </div>
  </div>

  )
}

export default AttachmentsModalScreen;
