// import './App.css';
import Main from './layouts/main';


function App() {
  return (
    <Main></Main>
  );
}

export default App;
