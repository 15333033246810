import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';

import useAuthToken from '../../components/app/AuthToken';

import { ApiContext } from '../../components/app/contexts/ApiContext';
import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';

function AdminChangePassword() {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const {authToken, setAuthToken} = useAuthToken();

  const [adminOldPassword, setAdminOldPassword] = useState('');
  const [adminNewPassword, setAdminNewPassword] = useState('');
  const [adminNewPasswordAgain, setAdminNewPasswordAgain] = useState('');
  const [adminNewPasswordValidity, setAdminNewPasswordValidity] = useState(-1);

  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id === 'adminOldPassword'){
      await setAdminOldPassword(value);
    }

    if(id === 'adminNewPassword'){
      await setAdminNewPassword(value);
      await setAdminNewPasswordValidity(value === adminNewPasswordAgain);
    }

    if(id === 'adminNewPasswordAgain'){
      await setAdminNewPasswordAgain(value);
      await setAdminNewPasswordValidity(value === adminNewPassword);

    }


  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if(adminNewPasswordValidity == true){
      const response = await api.admin.changePassword(
        adminOldPassword,
        adminNewPassword
      );

      if( response.status == true ){
        await setAuthToken(response.token);

        notifications.admin.notification('change_password');

        setTimeout(() => {
          window.location.href = '/logout';
        }, 2500);
      } else {
        notifications.admin.notification(response.error);
      }
    }

  }


  function renderAdminPassword(){

    var adminNewPasswordValidityClass = '';
    if(adminNewPasswordValidity === true){
      adminNewPasswordValidityClass = 'uk-form-success';
    }

    if(adminNewPasswordValidity === false){
      adminNewPasswordValidityClass = 'uk-form-danger';
    }


    return (
      <form id="adminPasswordForm" className="uk-form-vertical" onSubmit={handleSubmit}>

        <legend className="uk-legend uk-text-center uk-margin">Ваши доступы:</legend>

        <div className="" id="adminOldPasswordBox">
          <label className="uk-form-label uk-text-center" htmlFor="adminOldPassword">Старый пароль:</label>
          <div className="uk-form-controls">
            <input id="adminOldPassword"
                   className="uk-input"
                   name="adminOldPassword"
                   type="password"
                   placeholder="Old password"
                   onChange={handleInputChange}
                   value={adminOldPassword}
            />
          </div>
        </div>

        <div className="uk-margin" id="adminNewPasswordBox">
          <label className="uk-form-label uk-text-center" htmlFor="adminNewPassword">Новый пароль:</label>
          <div className="uk-form-controls">
            <input id="adminNewPassword"
                   className={"uk-input " + adminNewPasswordValidityClass}
                   name="adminNewPassword"
                   type="password"
                   placeholder="New password"
                   value={adminNewPassword}
                   onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="uk-margin" id="adminNewPasswordAgainBox">
          <label className="uk-form-label uk-text-center" htmlFor="adminNewPasswordAgain">Повторите новый пароль:</label>
          <div className="uk-form-controls">
            <input id="adminNewPasswordAgain"
                   className={"uk-input " + adminNewPasswordValidityClass}
                   name="adminNewPasswordAgain"
                   type="password"
                   placeholder="New password again"
                   value={adminNewPasswordAgain}
                   onChange={handleInputChange}
            />
            <small hidden={adminNewPasswordValidity} className={adminNewPasswordValidityClass}>Пароли не совпадают</small>
          </div>
        </div>


        <button id='submitAdminPassword' type="submit" className="uk-button uk-button-primary uk-align-center custom-button-success">Изменить пароль</button>

      </form>
    );
  }


  return (
    <div className="uk-container">

      <Breadcrumbs>
        <Breadcrumb><NavLink to="/">Панель администратора</NavLink></Breadcrumb>
        <Breadcrumb><NavLink to="/profile">Настройки профиля</NavLink></Breadcrumb>
        <Breadcrumb><span>Смена пароля</span></Breadcrumb>
      </Breadcrumbs>

      <h1 className="uk-heading-line uk-text-center">
        <span>Смена пароля</span>
      </h1>

      <h1 className="uk-heading-divider"></h1>

      <div className="uk-grid-collapse uk-grid uk-flex uk-flex-center custom-admin-change-password" data-uk-grid>

        <div className="uk-width-1-3 uk-padding-small">
          {renderAdminPassword()}
        </div>

      </div>

    </div>

  )
}

export default AdminChangePassword;
