import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { ApiContext } from '../../../components/app/contexts/ApiContext';
import { NotificationsContext } from '../../../components/app/contexts/NotificationsContext';


function FilesModalScreen(props) {

  const api = useContext(ApiContext);
  const notifications = useContext(NotificationsContext);

  const siteCatalogueId = props.siteCatalogueId;

  const [modal, setModal] = props.modal;
  const [facebookPixelId, setFacebookPixelId] = useState('');
  const [indexFile, setIndexFile] = useState('');
  const [htaccessFile, setHtaccessFile] = useState('');

  var modalStateClass = ' uk-open';


  const handleInputChange = (e) => {
    var {id, value} = e.target;

    if(id === 'facebookPixelId'){
      setFacebookPixelId(value);
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const responseIndex = await api.siteCatalogue.files.index(
      siteCatalogueId,
      facebookPixelId
    );

    if(responseIndex.status === true){
      setIndexFile(responseIndex.data);
    } else {
      if(responseIndex.error.indexOf('invalid_facebook_pixel_id') !== -1){
        const errorParts = responseIndex.error.split('::');
        notifications.siteCatalogue.files.notification(errorParts[0], String('<b>').concat(errorParts[1]).concat('</b>'));
      } else {
        notifications.siteCatalogue.files.notification(responseIndex.error);
      }
      return false;
    }


    const responseHtaccess = await api.siteCatalogue.files.htaccess(
      siteCatalogueId
    );

    if(responseHtaccess.status === true){
      setHtaccessFile(responseHtaccess.data);
    }

    return false;
  }


  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setModal([]);
    return false;
  }


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    notifications.siteCatalogue.files.notification('file_copied_to_clipboard');
  };


  function renderFiles(){
    var result = [];

    return (
      <>
        {(indexFile != '' || htaccessFile != '') && (
          <>
            <hr/>

            <div className="uk-alert-primary" data-uk-alert>
              Пожалуйста, обратите внимание на то, что после загрузки файла браузером его имя может быть изменено системой - для правильной работы интеграции файлы должны именоваться строго <i>index.php</i> и <i>.htaccess</i> с соблюдением всех символов (в частности точка в <i>.htaccess</i>).
            </div>
          </>
        )}
        <div className="uk-grid uk-grid-small site-files-wrapper" data-uk-grid>
          {(indexFile != '') && (
            <div className="uk-width-1-2">
              <h3>Файл <i>index.php</i></h3>
              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-1 uk-margin-bottom">
                  <pre>
                    <code>
                      {indexFile}
                    </code>
                  </pre>
                </div>

                <div className="uk-width-1-1">
                  <button className="uk-button uk-button-default" type="button" onClick={(e) => {copyToClipboard(indexFile);}}>
                    <span className="uk-icon" data-uk-icon="copy"></span> Копировать в буфер обмена
                  </button>

                  <NavLink
                    className="uk-button uk-button-default uk-margin-small-left"
                    to={String("data:application/octet-stream;charset=utf-8;base64,").concat(window.btoa(unescape(encodeURIComponent(indexFile))))}
                    download="index.php"
                  >
                    <span className="uk-icon" data-uk-icon="download"></span> index.php
                  </NavLink>
                </div>

              </div>
            </div>
          )}

          {(htaccessFile != '') && (
            <div className="uk-width-1-2">
              <h3>Файл <i>.htaccess</i></h3>
              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-1 uk-margin-bottom">
                  <pre>
                    <code>
                      {htaccessFile}
                    </code>
                  </pre>
                </div>

                <div className="uk-width-1-1">
                  <button className="uk-button uk-button-default" type="button" onClick={(e) => {copyToClipboard(htaccessFile);}}>
                    <span className="uk-icon" data-uk-icon="copy"></span> Копировать в буфер обмена
                  </button>

                  <NavLink
                    className="uk-button uk-button-default uk-margin-small-left"
                    to={String("data:message/example;charset=utf-8;base64,").concat(window.btoa(unescape(encodeURIComponent(htaccessFile))))}
                    download=".htaccess"
                  >
                    <span className="uk-icon" data-uk-icon="download"></span> .htaccess
                  </NavLink>
                </div>

              </div>
            </div>
          )}

        </div>
      </>
    );
  }


  return (

  <div className={"uk-modal-container" + modalStateClass} data-uk-modal style={{display: 'block'}}>
    <div className="uk-modal-dialog">
      <form id="hierModalForm" className="uk-form-stacked" onSubmit={handleSubmit}>
        <button className="uk-modal-close-default" data-uk-close type="button" onClick={handleCancel}></button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">Файлы интеграции</h2>
        </div>
        <div className="uk-modal-body">

          <p>Для того, чтобы сайт смог работать с хабом для заказов, необходимо загрузить данные файлы интеграции в корневую папку лендинга.</p>

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="name">Facebook Pixel ID:</label>
            <div className="uk-form-controls">
              <input
                id="facebookPixelId"
                name="facebookPixelId"
                value={facebookPixelId}
                onChange={handleInputChange}
                className="uk-input"
                placeholder="Идентификаторы через запятую"
              />
            </div>
          </div>

          {renderFiles()}

        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default" type="button" onClick={handleCancel}>Отмена</button>
          <button className="uk-button uk-button-primary custom-button-success" type="submit">Сгенерировать</button>
        </div>
      </form>
    </div>
  </div>

  )
}

export default FilesModalScreen;
