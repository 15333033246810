export function renderDateTime(timestamp, mode = false){

  var date_time = '-';

  if(timestamp){
    var a = new Date(timestamp * 1000);
    var months = ['Января','Февраля','Марта','Апреля','Мая','Июня','Июля','Августа','Сентября','Октября','Ноября','Декабря'];
    var year = a.getFullYear();
    var month = mode != false ? (a.getMonth() + 1) : months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();

    if(mode && (month < 10) ){
      month = '0' + month;
    }

    if(hour < 10){
      hour = '0' + hour;
    }

    if(min < 10){
      min = '0' + min;
    }

    if(sec < 10){
      sec = '0' + sec;
    }

    if(mode){
      date_time = date + '.' + month + '.' + year + ' ' + hour + ':' + min + ':' + sec ;
    } else {
      date_time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    }
  }


  return date_time;
}

export function renderDateInput(timestamp){

  var date_time = '-';

  if(timestamp){
    var a = new Date(timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date = a.getDate();

    if(month < 10){
      month = "0" + month;
    }

    date_time = year + '-' + month + '-' + date;
  }


  return date_time;
}

export function renderDateInputTime(timestamp){

  var date_time = '-';

  if(timestamp){
    var a = new Date(timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date = a.getDate();
    var hours = a.getHours();
    var minutes = a.getMinutes();
    var seconds = a.getSeconds();

    if(date < 10){
      date = "0" + date;
    }

    if(month < 10){
      month = "0" + month;
    }

    if(hours < 10){
      hours = "0" + hours;
    }

    if(minutes < 10){
      minutes = "0" + minutes;
    }

    if(seconds < 10){
      seconds = "0" + seconds;
    }

    date_time = year + '-' + month + '-' + date + 'T' + hours + ':' + minutes + ':' + seconds;
  }


  return date_time;
}

export function renderDateTimestamp(date){

  var timestamp = false;

  if(date){
    var dateParts = date.split('T');
    var datePart = dateParts[0].split('-');
    var timePart = dateParts[1].split(':');
    var year = datePart[0];
    var month = datePart[1];
    var date = datePart[2];
    var hours = timePart[0];
    var minutes = timePart[1];

    console.log("Date inside", year, month, date, hours, minutes);
    var temp = new Date(year, month - 1, date, hours, minutes);
    timestamp = temp.getTime() / 1000;

  }

  return timestamp;
}

export function renderDateTimestampDate(date){

  var timestamp = false;

  if(date){
    var datePart = date.split('-');
    var year = datePart[0];
    var month = datePart[1];
    var date = datePart[2];
    var hours = 0;
    var minutes = 0;

    console.log("Date inside", year, month, date, hours, minutes);
    var temp = new Date(year, month - 1, date, hours, minutes);
    timestamp = temp.getTime() / 1000;

  }

  return timestamp;
}


export function roundFloat(value, precision = 100){
  return parseFloat(Math.round(value * precision) / precision);
}


export function truncateString(str, num = 100) {

  if(!str){
    return str;
  }

  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
