import {useState, useEffect} from 'react';
import  { NavLink } from "react-router-dom";

import AttachmentsGrid from './attachments/AttachmentsGrid';

function UpsellAttachments(props){

  const [attachments, setAttachments] = props.attachments;

  return (
    <div className="uk-width-1-1" id="attachmentsBox">
      <div className="uk-form-controls">
        <AttachmentsGrid
          attachments={[attachments, setAttachments]}
        />
      </div>
    </div>
  );
}

export default UpsellAttachments;
