import { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";

import {renderDateTime} from '../../../components/app/Functions';

import OptionsCalculator from './options/calculator/Options';
import OptionsVariations from './options/variations/Options';


function Variation(props){

  const index = parseInt(props.index);
  const type = props.type;

  const [formula, setFormula] = props.formula;
  const [opened, setOpened] = props.opened;


  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id == "formula[" + index + "][name]"){

      var formulaNew = [...formula];
      formulaNew[index].name = value;

      setFormula(formulaNew);
    }
  }


  const handleDelete = async (e) => {

    var formulaNew = [...formula];
    formulaNew = formulaNew.filter((elem, i) => {
      return i !== index;
    });

    setFormula(formulaNew);
  }


  const handleArrowUp = (e) => {
    if(index === 0){
      return false;
    }

    var formulaNew = [...formula];
    var temp = formulaNew[index - 1];
    formulaNew[index - 1] = formulaNew[index];
    formulaNew[index] = temp;

    setFormula(formulaNew);
  }

  const handleArrowDown = (e) => {
    if( (index + 1) === formula.length){
      return false;
    }

    var formulaNew = [...formula];
    var temp = formulaNew[index + 1];
    formulaNew[index + 1] = formulaNew[index];
    formulaNew[index] = temp;

    setFormula(formulaNew);
  }


  function renderArrows(){

    var arrowUp = null;
    var arrowDown = null;

    if(index !== 0){
      arrowUp = (
        <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-up" onClick={handleArrowUp}></span>
      );
    }

    if( (index + 1) !== formula.length){
      arrowDown = (
        <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-down" onClick={handleArrowDown}></span>
      );
    }

    return (
      <div className="arrows">
        {arrowUp}
        {arrowDown}
      </div>
    );
  }



  const handleOpened = async (e) => {
    var newOpened = [...opened];

    var variationId = type + '-' + index;

    if(newOpened.indexOf(variationId) !== -1){
      // Removes the ID from opened
      newOpened = newOpened.filter((item, i) => {
        return item != variationId;
      });
    } else {
      // Adds the ID to opened
      newOpened.push(variationId);
    }

    setOpened(newOpened);
  };


  function renderVariationContent(){

    var content = null;
    var variationId = type + '-' + index;

    var dataOpened = opened.indexOf(variationId) !== -1 ? 1 : 0;

    if(type === 'calculator' && dataOpened){
      content = (
        <OptionsCalculator
          type={type}
          formula={[formula, setFormula]}
          index={index}
        />
      );
    }


    if(type === 'variations' && dataOpened){
      content = (
        <OptionsVariations
          type={type}
          formula={[formula, setFormula]}
          index={index}
        />
      );
    }


    return (
      <div className="uk-card-footer site-variation-content" data-opened={dataOpened}>
        {content}
      </div>
    );

  }


  function renderOpenTitle(){
    var variationId = type + '-' + index;
    var dataOpened = opened.indexOf(variationId) !== -1 ? 1 : 0;

    return dataOpened == 0 ? 'Развернуть' : 'Свернуть';
  }


  function checkUniqueId(){

    for (var i = 0; i < formula.length; i++) {
      if(i === index){
        continue;
      }

      if(formula[i].name != '' && formula[i].name == formula[index].name){
        return true;
      }
    }

    return false
  }


  return (
    <div className="uk-card uk-card-default uk-card-small">

      <div className="uk-card-body">
        <div className="uk-flex uk-flex-middle">

          <div className="uk-width-1-6 uk-text-center">
            {renderArrows()}
          </div>

          <div className="uk-width-3-6 uk-margin-right">
            <h4 className="uk-margin-remove site-variation-title uk-grid-small" data-uk-grid>
              <span className="uk-width-1-1 uk-text-left">
                <input
                  id={"formula[" + index + "][name]"}
                  className="uk-input"
                  type="text"
                  placeholder="Идентификатор вариации"
                  name={"formula[" + index + "][name]"}
                  onChange={handleInputChange}
                  value={formula[index].name}
                  required={true}
                  style={checkUniqueId() ? {borderColor: 'red'} : {}}
                />
              </span>
            </h4>
          </div>

          <div className="uk-width-1-6 uk-text-center site-variation-open-title" onClick={handleOpened}>
            <span className="uk-text-center uk-text-center" uk-icon="icon: settings" data-uk-tooltip={renderOpenTitle()}></span>
          </div>

          <div className="uk-width-1-6 uk-text-center">
            <span className="uk-text-center trash-button" uk-icon="icon: trash" onClick={handleDelete}></span>
          </div>

        </div>
      </div>

      {renderVariationContent()}

    </div>
  );
}

export default Variation;
