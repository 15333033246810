import { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";

import Variation from "./Variation";


function Variations(props){

  const [formula, setFormula] = props.formula;
  const [opened, setOpened] = useState([]);
  const type = props.type;
  const typesMap = props.typesMap;


  useEffect(() => {
    var newOpened = [];
    for (var i = 0; i < formula.length; i++) {
      newOpened.push(
        String(type).concat('-').concat(i)
      );
    }
    setOpened(newOpened);
  }, []);

  const handleAdd = (event) => {

    var newVariations = [...formula];

    var variation = {
      name: '',
      options: []
    };

    newVariations.push(variation);
    setFormula(newVariations);

    setOpened([
      ...opened,
      String(type).concat('-').concat(newVariations.length - 1)
    ]);
  }


  function renderVariations(){

    var items = [];

    formula.forEach((item, i) => {

      var variation = (
        <Variation
          formula={[formula, setFormula]}
          type={type}
          index={i}
          opened={[opened, setOpened]}
        />
      );

      items.push(
        <li key={'variation-' + i}>
          {variation}
        </li>
      );
    });



    var result = (
      <div>
        <div className="uk-text-left uk-margin-top">
          <h2>Параметры расчета <b>{typesMap[type]}</b></h2>
        </div>
        <ul className="uk-grid-small uk-margin uk-child-width-1-1 site-variations-wrapper" data-uk-grid>
          {items}

          <li>
            <div className="uk-card uk-card-default uk-card-small uk-card-body uk-text-center site-variation-add" onClick={handleAdd}>

              <div className="uk-flex uk-flex-middle">

                <div className="uk-width-1-1">
                  <span className="uk-text-center" uk-icon="icon: plus"></span>
                </div>

              </div>

            </div>
          </li>
        </ul>
      </div>
    );


    return result;
  }

  return (
    <>
      {renderVariations()}
    </>
  );
}

export default Variations;
