import AbstractApi from "../AbstractApi";

class FilesApi extends AbstractApi  {

  // Files
  indexMethod = 'dashboard/sites_catalogues/{:id}/files/index';
  htaccessMethod = 'dashboard/sites_catalogues/{:id}/files/htaccess';


  /*--------------------------------------------------------------------------*/
  /* Files */

  async index(id, facebookPixelId = false) {

    this.requestMethod = this.indexMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    if(facebookPixelId != false){
      this.requestData.append('facebook_pixel_id', facebookPixelId);
    }

    return await this.request();
  }


  async htaccess(id) {

    this.requestMethod = this.htaccessMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }

}

export default FilesApi;
