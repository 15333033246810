import { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";

import { RoleContext } from '../../components/app/contexts/RoleContext';

import filterOrderByIcon from '../../assets/img/filter-left.svg';
import filterOrderIcon from '../../assets/img/arrow-down-up.svg';
import filterCrmBuyerIdIcon from '../../assets/img/people-fill.svg';
import filterGoodsCategoryIdIcon from '../../assets/img/bookmark-star.svg';


function SitesCataloguesTableFilter(props){

  const role = useContext(RoleContext);

  const [filterOrderBy, setFilterOrderBy] = props.filterOrderBy;
  const [filterOrder, setFilterOrder] = props.filterOrder;
  const [filterSearch, setFilterSearch] = props.filterSearch;
  const [filterCrmBuyerId, setFilterCrmBuyerId] = props.filterCrmBuyerId;
  const [filterGoodsCategoryId, setFilterGoodsCategoryId] = props.filterGoodsCategoryId;
  const [filterPage, setFilterPage] = props.filterPage;

  const getData = props.getData;
  const crmBuyerIdsMeta = props.crmBuyerIdsMeta;
  const upsellsCategoriesMeta = props.upsellsCategoriesMeta;

  const handleFilterChange = async (e) => {
    e.preventDefault();

    var {id, value} = e.target;

    if(id == 'filterOrderBy'){
      await setFilterPage(1);
      await setFilterOrderBy(value);
    }

    if(id == 'filterOrder'){
      await setFilterPage(1);
      await setFilterOrder(value);
    }

    if(id == 'filterSearch'){
      await setFilterPage(1);
      await setFilterSearch(value);
    }

    if(id == 'filterCrmBuyerId'){
      await setFilterPage(1);
      await setFilterCrmBuyerId(value);
    }

    if(id == 'filterGoodsCategoryId'){
      await setFilterPage(1);
      await setFilterGoodsCategoryId(value);
    }

  }

  function renderCrmBuyerIdOptions() {
    var options = [];
    options.push(
      <option key="crm-buyer-id-default" value="">Buyer ID</option>
    );

    Object.keys(crmBuyerIdsMeta).forEach((item, i) => {

      if(!item){
        return;
      }

      options.push(
        <option key={"crm-buyer-id-" + i} value={item}>{item}</option>
      );
    });

    return options;
  }


  function renderUpsellsCategoriesOptions() {
    var options = [];
    options.push(
      <option key="upsells-category-id-default" value="">Категория</option>
    );

    upsellsCategoriesMeta.forEach((item, i) => {
      options.push(
        <option key={"upsells-category-id-" + i} value={item.id}>{item.name}</option>
      );
    });

    return options;
  }


  return (
    <div className="uk-flex-right uk-grid-collapse" data-uk-grid>

      <div id="filterSearchDiv" style={{maxWidth: '120px'}}>
        <form className="uk-search uk-search-default">
          <span data-uk-search-icon></span>
          <input
            className="uk-search-input"
            type="search"
            id="filterSearch"
            name="filterSearch"
            placeholder="Поиск"
            value={filterSearch}
            onChange={handleFilterChange}
          />
        </form>
      </div>

      {(role == 'owner' || role == 'admin') && (

        <div id="filterCrmBuyerIdDiv" style={{maxWidth: '7rem'}}>
          <picture>
            <img src={filterCrmBuyerIdIcon} />
          </picture>
          <select
            className="uk-select"
            id="filterCrmBuyerId"
            name="filterCrmBuyerId"
            value={filterCrmBuyerId}
            onChange={handleFilterChange}
          >
            {renderCrmBuyerIdOptions()}
          </select>
        </div>
      )}

      <div id="filterUpsellsCategoryIdDiv" style={{maxWidth: '7.4rem'}}>
        <picture>
          <img src={filterGoodsCategoryIdIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterGoodsCategoryId"
          name="filterGoodsCategoryId"
          value={filterGoodsCategoryId}
          onChange={handleFilterChange}
        >
          {renderUpsellsCategoriesOptions()}
        </select>
      </div>

      <div id="filterOrderByDiv" style={{maxWidth: '7.2rem'}}>
        <picture>
          <img src={filterOrderByIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrderBy"
          name="filterOrderBy"
          value={filterOrderBy}
          onChange={handleFilterChange}
        >
          <option value="id">ID</option>
          <option value="name">Идентификатор</option>
          <option value="internal_name">Название оффера</option>
          <option value="url">Ссылка на сайт</option>
          <option value="crm_shop">CRM Shop</option>
          <option value="crm_buyer_id">Buyer ID</option>
          <option value="catalogue_order_total_min">Минимальная сумма заказа</option>
          <option value="created">Создан</option>
          <option value="modified">Обновлен</option>
        </select>
      </div>

      <div id="filterOrderDiv" style={{maxWidth: '7.2rem'}}>
        <picture>
          <img src={filterOrderIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrder"
          name="filterOrder"
          value={filterOrder}
          onChange={handleFilterChange}
        >
          <option value="desc">По убыванию</option>
          <option value="asc">По возрастанию</option>
        </select>
      </div>

    </div>
  );
}

export default SitesCataloguesTableFilter;
