import AbstractNotifications from '../AbstractNotifications';

class CategoriesNotifications extends AbstractNotifications {
  messages = {
    'upsells_category_created': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Категория товаров допродаж создана'
    },
    'upsells_category_updated': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },
    'upsells_category_deleted': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },

    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    },
    'existing_upsells_category_name': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Категория с таким названием уже существует'
    }
  }
}

export default CategoriesNotifications;
