import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

function Block(props){

  const [upsells, setUpsells] = props.upsells;
  const upsellsMeta = props.upsellsMeta;
  const upsellsCategoriesMeta = props.upsellsCategoriesMeta;
  const typesMap = props.typesMap;
  const index = parseInt(props.index);


  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id == "upsells[" + index + "][type]"){

      var upsellsNew = [...upsells];
      upsellsNew[index].type = value;
      upsellsNew[index].id = false;

      setUpsells(upsellsNew);
    }

    if(id == "upsells[" + index + "][id]"){

      var upsellsNew = [...upsells];
      upsellsNew[index].id = value ? parseInt(value) : '';

      setUpsells(upsellsNew);
    }
  }


  const handleDelete = (e) => {

    var upsellsNew = [...upsells];
    upsellsNew = upsellsNew.filter((elem, i) => {
      return i !== index;
    });

    setUpsells(upsellsNew);
  }


  // const handleArrowUp = (e) => {
  //   if(index === 0){
  //     return false;
  //   }
  //
  //   var categoriesNew = [...categories];
  //   var temp = categoriesNew[index - 1];
  //   categoriesNew[index - 1] = categoriesNew[index];
  //   categoriesNew[index] = temp;
  //
  //   setCategories(categoriesNew);
  // }
  //
  // const handleArrowDown = (e) => {
  //   if( (index + 1) === categories.length){
  //     return false;
  //   }
  //
  //   var categoriesNew = [...categories];
  //   var temp = categoriesNew[index + 1];
  //   categoriesNew[index + 1] = categoriesNew[index];
  //   categoriesNew[index] = temp;
  //
  //   setCategories(categoriesNew);
  // }
  //
  //
  // function renderArrows(){
  //
  //   var arrowUp = null;
  //   var arrowDown = null;
  //
  //   if(index !== 0){
  //     arrowUp = (
  //       <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-up" onClick={handleArrowUp}></span>
  //     );
  //   }
  //
  //   if( (index + 1) !== categories.length){
  //     arrowDown = (
  //       <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-down" onClick={handleArrowDown}></span>
  //     );
  //   }
  //
  //   return (
  //     <>
  //       {arrowUp}
  //       {arrowDown}
  //     </>
  //   );
  // }


  function renderTypesOptions(){

    var options = [];

    Object.keys(typesMap).forEach((item, i) => {
      options.push(
        <option key={"upsell-type-option-" + index + "-" + i} value={item}>{typesMap[item]}</option>
      );
    });

    return options;
  }


  function renderUpsellsOptions(type = 'upsell'){

    const defaultOption = type == 'upsell' ? '-- Товар --' : '-- Категория --';
    const itemsMeta = type == 'upsell' ? upsellsMeta : upsellsCategoriesMeta;

    var options = [];
    options.push(
      <option key={"upsell-item-option-" + index + "-default"} value="">{defaultOption}</option>
    );

    var usedIds = [];

    for (var i = 0; i < upsells.length; i++) {
      if(upsells[i].type == type){
        usedIds.push(upsells[i].id);
      }
    }

    for (var i = 0; i < itemsMeta.length; i++) {
      if( (upsells[index].type == type && upsells[index].id == itemsMeta[i].id) || !usedIds.includes(itemsMeta[i].id)){
        options.push(
          <option key={"upsell-item-option-" + index + "-" + i} value={itemsMeta[i].id}>{itemsMeta[i].name}</option>
        );
      }
    }

    return options;
  }


  return (
    <div className="uk-card uk-card-default uk-card-body uk-card-small">

      <div className="uk-flex uk-flex-middle">

        {/*<div className="uk-width-1-6">
          {renderArrows()}
        </div>*/}

        <div className="uk-width-1-3 uk-margin-right" id={"typeBox-" + index}>
          <div className="uk-form-controls">
            <select
              id={"upsells[" + index + "][type]"}
              className="uk-select"
              name={"upsells[" + index + "][type]"}
              onChange={handleInputChange}
              value={upsells[index].type}
              required={true}
            >
              {renderTypesOptions()}
            </select>
          </div>
        </div>

        <div className="uk-width-1-2 uk-margin-right" id={"itemBox-" + index}>
          <div className="uk-form-controls">
            <select
              id={"upsells[" + index + "][id]"}
              className="uk-select"
              name={"upsells[" + index + "][id]"}
              onChange={handleInputChange}
              value={upsells[index].id}
              required={true}
            >
              {renderUpsellsOptions(upsells[index].type)}
            </select>
          </div>
        </div>

        <div className=" uk-text-center">
          <span className="uk-margin-small-right uk-text-center trash-button" data-upsell-index={index} uk-icon="icon: trash" onClick={handleDelete}></span>
        </div>

      </div>

    </div>
  );
}

export default Block;
