import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../components/app/Functions';


function UpsellsTablePosition(props){

  const item = props.item;
  const handleSingleSelection = props.handleSingleSelection;

  const status = item.status ? (<span className="uk-text-center icon-success" data-uk-tooltip="Активен" uk-icon="icon: check"></span>) : (<span className="uk-text-center icon-danger" data-uk-tooltip="Неактивен" uk-icon="icon: close"></span>);
  const image = item.default_image.length > 0 ? (<NavLink to={item.default_image[0].media} data-caption={item.default_image[0].comment}><img src={item.default_image[0].media} alt={item.default_image[0].comment} /></NavLink>) : '';

  return (
    <tr key={item.id}>
      <td>
        <input type="checkbox" name="deleteItems[]" value={item.id} onChange={handleSingleSelection}/>
      </td>
      <td>
        {item.id}
      </td>
      <td className="upsells-table-img" data-uk-lightbox>
        {image}
      </td>
      <td>
        <NavLink className="custom-link" to={['', 'upsells', item.id].join('/')}>{item.name}</NavLink>
      </td>
      <td className="uk-text-center">
        {status}
      </td>
      <td className="uk-text-center">
        {item.priority}
      </td>
      <td className="uk-text-center">
        <b>{item.price}</b>
      </td>
      <td className="uk-text-center">
        <u>{item.external_id}</u> ✕ {item.quantity}
      </td>
      <td className="uk-text-meta">
        {renderDateTime(item.created)}
      </td>
      <td className="uk-text-meta">
        {renderDateTime(item.modified)}
      </td>
    </tr>
  );
}

export default UpsellsTablePosition;
