import { useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import NavbarAdmin from '../navbar/NavbarAdmin';

import Notifications from '../app/notifications/Notifications';
import ApiHandler from '../../api/ApiHandler';

import { NotificationsContext } from '../app/contexts/NotificationsContext';
import { ApiContext } from '../app/contexts/ApiContext';
import { RoleContext } from '../app/contexts/RoleContext';

import useAuthToken from "../app/AuthToken";
import useAuthRole from "../app/AuthRole";

function ContainerAdmin() {

  const {authToken, setAuthToken} = useAuthToken();
  const {authRole, setAuthRole} = useAuthRole();

  const api = new ApiHandler(authToken);
  const notifications = new Notifications();

  return (
    <RoleContext.Provider value={authRole}>
      <ApiContext.Provider value={api}>
        <NotificationsContext.Provider value={notifications}>
          <NavbarAdmin />
          <div className="uk-section uk-padding-remove-top custom-section-outer">
              <div className="uk-container">
                <Outlet />
              </div>
          </div>
        </NotificationsContext.Provider>
      </ApiContext.Provider>
    </RoleContext.Provider>
  )
}

export default ContainerAdmin;
