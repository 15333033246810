import AbstractApi from "../AbstractApi";

class CategoriesApi extends AbstractApi  {

  // Categories
  createMethod = 'dashboard/upsells/categories/create';
  getFilterMethod = 'dashboard/upsells/categories/get';
  getMethod = 'dashboard/upsells/categories/{:id}/get';
  updateMethod = 'dashboard/upsells/categories/{:id}/update';
  deleteMethod = 'dashboard/upsells/categories/{:id}/delete';

  /*--------------------------------------------------------------------------*/
  /* Categories */

  async create(name) {

    this.requestMethod = this.createMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('name', name);

    return await this.request();
  }


  async get(id = false, filterOrderBy = 'name', filterOrder = 'asc', filterSearch = false, paginationPage = 1, paginationLimit = 50) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;

      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async update(id, name) {

    this.requestMethod = this.updateMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('name', name);

    return await this.request();
  }


  async delete(id) {

    this.requestMethod = this.deleteMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }

}

export default CategoriesApi;
