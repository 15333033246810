import AbstractNotifications from '../AbstractNotifications';

class ImagesNotifications extends AbstractNotifications {
  messages = {
    'upsell_image_created': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Вложение товара допродаж создано'
    },
    'upsell_image_updated': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Вложение товара допродаж обновлено'
    },
    'upsell_image_deleted': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Вложение товара допродаж удалено'
    },

    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    }
  }
}

export default ImagesNotifications;
