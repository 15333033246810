import AbstractNotifications from './AbstractNotifications';

class AdminNotifications extends AbstractNotifications {
  messages = {
    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    },
    'similar_passwords': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Новый пароль должен отличаться от старого'
    },
    'wrong_credentials': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Проверьте введенные доступы'
    },
    'change_password': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Пароль успешно изменен'
    },
    'change_password_error': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Проверьте введенные доступы'
    }
  }
}

export default AdminNotifications;
