import {useState} from "react";
import useAuthToken from '../../components/app/AuthToken';
import useAuthLogin from '../../components/app/AuthLogin';
import useAuthRole from '../../components/app/AuthRole';

function LogoutAdmin() {

  const {authToken, setAuthToken} = useAuthToken();
  const {authLogin, setAuthLogin} = useAuthLogin();
  const {authRole, setAuthRole} = useAuthRole();

  if(authToken != null){
    setAuthToken(null);
    setAuthLogin(null);
    setAuthRole(null);

    window.location.href = '/';
  }

}

export default LogoutAdmin;
