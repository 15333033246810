import AbstractApi from "./AbstractApi";

class LogsApi extends AbstractApi  {

  // Logs
  getFilterMethod = 'dashboard/logs/get';
  getMethod = 'dashboard/logs/{:id}/get';
  metaMethod = 'dashboard/logs/meta';

  /*--------------------------------------------------------------------------*/
  /* Logs */

  async get(id = false, filterOrderBy = 'created', filterOrder = 'desc', filterSearch = false, filterSection = false, filterUserId = false, filterCreated = false, paginationPage = 1, paginationLimit = 100) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;


      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      if(filterSection){
        this.requestData.append('filter[section]', filterSection);
      }

      if(filterUserId){
        this.requestData.append('filter[user_id]', filterUserId);
      }

      if(filterCreated){
        this.requestData.append('filter[created]', filterCreated);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async meta() {

    this.requestMethod = this.metaMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }


}

export default LogsApi;
