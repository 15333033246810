import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import Option from "./Option";


function Options(props){

  const type = props.type;
  const index = props.index;

  const [formula, setFormula] = props.formula;
  const [opened, setOpened] = useState([]);

  useEffect(() => {
    var newOpened = [];
    for (var i = 0; i < formula[index].options.length; i++) {
      newOpened.push(
        String(type).concat('-').concat(index).concat('-').concat(i)
      );
    }
    setOpened(newOpened);
  }, []);

  const handleAdd = async (event) => {

    var newVariations = [...formula];

    var from = 1;
    if(formula[index].options.length > 0){
      const tempFrom = parseInt(formula[index].options[formula[index].options.length - 1].to);
      from = tempFrom ? tempFrom : from;
    }

    var variation = {
      from: from,
      to: '',
      display: {
        old: '',
        new: '',
        discount: ''
      },
      price: '',
      price_crm: '',
      delivery: '',
      delivery_crm: '',
      external_id: ''
    };

    newVariations[index].options.push(variation);
    setFormula(newVariations);

    setOpened([
      ...opened,
      String(type).concat('-').concat(index).concat('-').concat(newVariations[index].options.length - 1)
    ]);
  }


  function renderOptions(){

    var items = [];

    formula[index].options.forEach((item, i) => {

      var option = (
        <Option
          type={type}
          index={index}
          subIndex={i}
          formula={[formula, setFormula]}
          opened={[opened, setOpened]}
        />
      );

      items.push(
        <li key={type + '-option-' + index + '-' + i}>
          {option}
        </li>
      );
    });

    var result = (
      <div>
        <ul className="uk-grid-small uk-margin uk-child-width-1-1 site-variations-options-wrapper" data-uk-grid>
          {items}

          <li>
            <div className="uk-card uk-card-default uk-card-small uk-card-body uk-text-center site-variation-option-add" onClick={handleAdd}>

              <div className="uk-flex uk-flex-middle">

                <div className="uk-width-1-1">
                  <span className="uk-text-center" uk-icon="icon: plus"></span>
                </div>

              </div>

            </div>
          </li>
        </ul>
      </div>
    );


    return result;
  }

  return (
    <>
      {renderOptions()}
    </>
  );
}

export default Options;
