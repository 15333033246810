import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { renderDateTime } from '../../components/app/Functions';
import SettingModalScreen from './modals/SettingModalScreen';

import SettingsTableFilter from './SettingsTableFilter';
import TablePagination from '../../components/common/tables/TablePagination';
import SettingsTablePosition from './SettingsTablePosition';

import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../components/app/contexts/ApiContext';

const settingsMap = {
  'orders_hub_integration_files_api_php': "Шаблоны файлов интеграции для сайта: api.php",
  'orders_hub_integration_files_htaccess': "Шаблоны файлов интеграции для сайта: htaccess",
  'default_gift_sum': "Подарок: стоимость товаров допродаж для активации подарка по умолчанию (в ценах CRM)",
  'default_gift_id': "Подарок: внешний ID подарка по умолчанию",
  'default_free_delivery_sum': "Бесплатная доставка: стоимость товаров допродаж для активации бесплатной доставки по умолчанию (в ценах CRM)",
  'default_free_delivery_id': "Бесплатная доставка: внешний ID бесплатной доставки по умолчанию",
  'default_catalogue_order_total_min': "Минимальная сумма заказа для сайта-каталога (в ценах CRM)",
  'orders_hub_integration_files_catalogue_index_php': "Шаблоны файлов интеграции для сайта-каталога: index.php",
  'orders_hub_integration_files_catalogue_htaccess': "Шаблоны файлов интеграции для сайта-каталога: htaccess",
  'orders_hub_integration_files_approvement_api_php': "Шаблоны файлов интеграции для сайта (метод подтверждения через TG-бот): api.php",
  'orders_hub_integration_files_approvement_htaccess': "Шаблоны файлов интеграции для сайта (метод подтверждения через TG-бот): htaccess",
  'default_bot_approvement_discount': "Скидка при подтверждении заказа через TG-бот (0 ... 1)",
  'bot_approvement_link': "Ссылка на TG-бот для подтверждения заказа",
  'bot_approvement_token': "Токен TG-бота для подтверждения заказа",
  'bot_approvement_webhook_url': "Webhook URL TG-бота для подтверждения заказа (рекомедовано не менять значение)",
  'default_sites_catalogues_image_url': "Ссылка на изображение сайта-каталога по умолчанию",
  'bot_approvement_marketing_stats_message': "Шаблон сообщения про завершение рассылки для TG-бота подтверждения заказов",

};

const typesMap = {
  'int': 'Целое число',
  'float': 'Дробное число',
  'double': 'Дробное число',
  'boolean': 'True | False',
  'bool': 'True | False',
  'string': 'Строка'
};


function SettingsTable(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const [data, setData] = useState([]);

  const [filterOrderBy, setFilterOrderBy] = useState('id');
  const [filterOrder, setFilterOrder] = useState('asc');
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLimit, setFilterLimit] = useState(50);
  const [filterPage, setFilterPage] = useState(1);
  const [filterTotalPages, setFilterTotalPages] = useState(1);

  const [modal, setModal] = useState([]);

  async function getData(){

    var dataFeed = await api.settings.get(false, filterOrderBy, filterOrder, filterSearch, filterPage, filterLimit);

    if(dataFeed.status === true){
      await setData(dataFeed.data);
      if(dataFeed.pagination.page){
        // await setFilterPage(dataFeed.pagination.page);
        await setFilterTotalPages(dataFeed.pagination.total);
      }
    }
  }

  useEffect( () => {
    getData();
  }, [filterOrderBy, filterOrder, filterSearch, filterPage, filterLimit]);


  const openModal = (e) => {
    e.preventDefault();

    var itemId = e.currentTarget.getAttribute('data-setting-id');

    setModal(
      <SettingModalScreen
        settingId={itemId}
        settings={data}
        modal={[modal, setModal]}
        getData={getData}
        settingsMap={settingsMap}
        typesMap={typesMap}
      />
    );


  }



  function renderTableRows(){

    var result = [];

    data.forEach((item, i) => {

      result.push(
        <SettingsTablePosition
          key={String('setting-').concat(i)}
          index={i}
          data={data}
          item={item}
          openModal={openModal}
          getData={getData}
          filterOrderBy={filterOrderBy}
          filterOrder={filterOrder}
          settingsMap={settingsMap}
          typesMap={typesMap}
        />
      );

    });


    if(result.length == 0){
      result.push(
        <tr key="no-entries-found" className="uk-text-lead uk-text-center uk-margin">
          <td colSpan="5">
            Нет данных
          </td>
        </tr>
      )
    }

    return result;
  }


  return (

    <>
      <div className="uk-grid-collapse" data-uk-grid>


        <div className="uk-width-1-1">

          <SettingsTableFilter
            filterOrderBy={[filterOrderBy, setFilterOrderBy]}
            filterOrder={[filterOrder, setFilterOrder]}
            filterSearch={[filterSearch, setFilterSearch]}
            filterPage={[filterPage, setFilterPage]}
            getData={getData}
          />

        </div>

      </div>

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />

      <table className="uk-table uk-table-divider uk-table-middle custom-table settings-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Настройки</th>
            <th>Значение</th>
            <th>Тип</th>
            <th className="uk-text-center" data-uk-tooltip="Редактировать">
              <span className="uk-icon" data-uk-icon="pencil"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>

      {modal}

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />

    </>

  )
}

export default SettingsTable;
