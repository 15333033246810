import AbstractApi from "../AbstractApi";

class ImagesApi extends AbstractApi  {

  // Images
  createMethod = 'dashboard/upsells/{:upsellId}/images/create';
  getFilterMethod = 'dashboard/upsells/{:upsellId}/images/get';
  getMethod = 'dashboard/upsells/{:upsellId}/images/{:id}/get';
  updateMethod = 'dashboard/upsells/{:upsellId}/images/{:id}/update';
  deleteMethod = 'dashboard/upsells/{:upsellId}/images/{:id}/delete';

  /*--------------------------------------------------------------------------*/
  /* Images */

  async create(upsellId, sort, media, comment = false) {

    this.requestMethod = this.createMethod.replace('{:upsellId}', upsellId);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('sort', sort);
    this.requestData.append('media', media);

    if(comment != false){
      this.requestData.append('comment', comment);
    }

    return await this.request();
  }


  async get(upsellId, id = false, filterOrderBy = 'sort', filterOrder = 'asc', filterSearch = false, paginationPage = 1, paginationLimit = 50) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:upsellId}', upsellId).replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod.replace('{:upsellId}', upsellId);

      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async update(upsellId, id, sort = false, media = false, comment = false) {

    this.requestMethod = this.updateMethod.replace('{:upsellId}', upsellId).replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(sort !== false){
      this.requestData.append('sort', sort);
    }

    if(media !== false){
      this.requestData.append('media', media);
    }

    if(comment !== false){
      this.requestData.append('comment', comment);
    }

    return await this.request();
  }


  async delete(upsellId, id) {

    this.requestMethod = this.deleteMethod.replace('{:upsellId}', upsellId).replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }

}

export default ImagesApi;
