import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../components/app/Functions';
import LogsTableFilter from './LogsTableFilter';
import TablePagination from '../../components/common/tables/TablePagination';
import LogsTablePosition from './LogsTablePosition';

import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../components/app/contexts/ApiContext';


const sectionsMap = {
  'dashboard': "Система",
  'users': "Пользователи",
  'upsells_categories': "Категории товаров допродаж",
  'upsells': "Товары допродаж",
  'upsells_images': "Вложения товаров допродаж",
  'sites': "Сайты",
  'sites_catalogues': "Сайты-каталоги",
  'settings': "Настройки",
  'facebook_pixels_tokens': "FB пиксели/токены",
  'delivery': "Адреса доставки"
};


function LogsTable() {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const [data, setData] = useState([]);

  const [filterOrderBy, setFilterOrderBy] = useState('created');
  const [filterOrder, setFilterOrder] = useState('desc');
  const [filterSearch, setFilterSearch] = useState('');
  const [filterSection, setFilterSection] = useState(false);
  const [filterUserId, setFilterUserId] = useState(false);
  const [filterCreated, setFilterCreated] = useState('');

  const [filterLimit, setFilterLimit] = useState(100);
  const [filterPage, setFilterPage] = useState(1);
  const [filterTotalPages, setFilterTotalPages] = useState(1);

  const [sectionsMeta, setSectionsMeta] = useState({});
  const [userIdsMeta, setUserIdsMeta] = useState({});


  async function getData(){

    var dataFeed = await api.logs.get(false, filterOrderBy, filterOrder, filterSearch, filterSection, filterUserId, filterCreated, filterPage, filterLimit);

    if(dataFeed.status == true){
      await setData(dataFeed.data);
      if(dataFeed.pagination.page){
        await setFilterTotalPages(dataFeed.pagination.total);
      }
    }
  }


  async function getMeta(){

    var dataFeed = await api.logs.meta();

    if(dataFeed.status == true){
      await setSectionsMeta(dataFeed.data.section);
      await setUserIdsMeta(dataFeed.data.user_id);
    }
  }

  useEffect(() => {
    getMeta();
  }, []);

  useEffect( () => {
    getData();
  }, [filterOrderBy, filterOrder, filterSearch, filterSection, filterUserId, filterCreated, filterPage, filterLimit]);


  function renderTableRows(){

    var result = [];

    data.forEach((item, i) => {

      result.push(
        <LogsTablePosition
          item={item}
          sectionsMeta={sectionsMeta}
          sectionsMap={sectionsMap}
          key={item.id}
        />
      );
    });


    if(result.length == 0){
      result.push(
        <tr key="no-entries-found" className="uk-text-lead uk-text-center uk-margin">
          <td colSpan="9">
            Нет данных
          </td>
        </tr>
      )
    }

    return result;
  }


  return (

    <>
      <div className="uk-grid-collapse" data-uk-grid>

        <div className="uk-width-1-1">

          <LogsTableFilter
            filterOrderBy={[filterOrderBy, setFilterOrderBy]}
            filterOrder={[filterOrder, setFilterOrder]}
            filterSearch={[filterSearch, setFilterSearch]}
            filterSection={[filterSection, setFilterSection]}
            filterUserId={[filterUserId, setFilterUserId]}
            filterCreated={[filterCreated, setFilterCreated]}
            filterPage={[filterPage, setFilterPage]}
            sectionsMeta={sectionsMeta}
            sectionsMap={sectionsMap}
            userIdsMeta={userIdsMeta}
            getData={getData}
          />

        </div>

      </div>

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />

      <table className="uk-table uk-table-divider uk-table-middle custom-table logs-table">
        <thead>
          <tr>
            <th>Создан</th>
            <th>Пользователь</th>
            <th>Раздел</th>
            <th>Сущность</th>
            <th>Сообщение</th>
            <th>Параметры</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>

      <TablePagination
        filterPage={[filterPage, setFilterPage]}
        filterTotalPages={[filterTotalPages, setFilterTotalPages]}
        getData={getData}
      />
    </>

  )
}

export default LogsTable;
