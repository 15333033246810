import icon from '../../assets/img/balloon.svg';

function NoPage() {
    return (

      <div className="uk-container">

        <div className="uk-padding uk-text-center">
          <img width="20%" src={icon} />
        </div>

        <h1 className="uk-text-center">Not found!</h1>

      </div>
    )
}

export default NoPage;
