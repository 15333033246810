import AbstractApi from "./AbstractApi";

class UsersApi extends AbstractApi  {

  // Users
  createMethod = 'dashboard/users/create';
  getFilterMethod = 'dashboard/users/get';
  getMethod = 'dashboard/users/{:id}/get';
  updateMethod = 'dashboard/users/{:id}/update';
  deleteMethod = 'dashboard/users/{:id}/delete';
  metaMethod = 'dashboard/users/meta';

  /*--------------------------------------------------------------------------*/
  /* Users */

  async create(login, password, buyerId = false, role = 'user') {

    this.requestMethod = this.createMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('login', login);
    this.requestData.append('password', password);

    if(buyerId != false){
      this.requestData.append('buyer_id', buyerId);
    }

    if(role != false){
      this.requestData.append('role', role);
    }

    return await this.request();
  }


  async get(id = false, filterOrderBy = 'login', filterOrder = 'asc', filterSearch = false, filterRole = false, paginationPage = 1, paginationLimit = 50) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;

      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      if(filterRole){
        this.requestData.append('filter[role]', filterRole);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async update(id, login, password = false, buyerId = false, role = 'user') {

    this.requestMethod = this.updateMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('login', login);

    if(password != false){
      this.requestData.append('password', password);
    }

    if(buyerId != false){
      this.requestData.append('buyer_id', buyerId);
    }

    if(role != false){
      this.requestData.append('role', role);
    }

    return await this.request();
  }


  async delete(id) {

    this.requestMethod = this.deleteMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }


  async meta() {

    this.requestMethod = this.metaMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }

}

export default UsersApi;
