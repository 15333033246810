import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../components/app/Functions';


function SitesCataloguesTablePosition(props){

  const item = props.item;
  const handleSingleSelection = props.handleSingleSelection;
  const openModal = props.openModal;

  const upsellsCategoriesMeta = props.upsellsCategoriesMeta;

  var upsellsCategories = [];

  if(item.goods && item.goods.length > 0){
    item.goods.forEach((good, i) => {
      if(good.type == 'category'){

        const upsellCategory = upsellsCategoriesMeta.filter((upsellsCategory, j) => {
          return upsellsCategory.id == good.id;
        });

        const upsellCategoryName = upsellCategory.length > 0 ? upsellCategory[0].name : false;

        if(upsellCategoryName){
          upsellsCategories.push(
            <div key={String("sites-goods-category-").concat(i)} className="sites-upsell-category">
              {upsellCategoryName}
            </div>
          );
        }

      }
    });
  }



  return (
    <tr key={item.id}>
      <td>
        <input type="checkbox" name="deleteItems[]" value={item.id} onChange={handleSingleSelection}/>
      </td>
      <td>
        {item.id}
      </td>
      <td>
        <NavLink className="uk-text-center custom-link" to={['', 'sites_catalogues', item.id].join('/')}>{item.name}</NavLink>
      </td>
      <td className="uk-text-center sites-url">
        {item.internal_name} {(item.url) && (<NavLink to={item.url} target="_blank" className="uk-icon" uk-icon="link"></NavLink>)}
      </td>
      <td className="uk-text-center">
        {item.crm.buyer_id}
      </td>
      <td className="uk-text-center">
        <b>{roundFloat(item.catalogue_order_total_min, 100)}</b>
      </td>
      <td className="uk-text-center">
        <small>{upsellsCategories}</small>
      </td>
      <td className="uk-text-meta">
        {renderDateTime(item.created)}
      </td>
      <td className="uk-text-meta">
        {renderDateTime(item.modified)}
      </td>
    </tr>
  );
}

export default SitesCataloguesTablePosition;
