import { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

function AttachmentsDeleteModalScreen(props) {

  const [data, setData] = props.data;
  const index = props.index;
  const [deleteModal, setDeleteModal] = props.deleteModal;

  var modalStateClass = ' uk-open';

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var attachmentsNew = [...data];
    attachmentsNew = attachmentsNew.filter((elem, i) => {
      return i !== parseInt(index);
    });

    // Resorts the array
    for (var i = 0; i < attachmentsNew.length; i++) {
      if(parseInt(attachmentsNew[i]['sort']) != i){
        attachmentsNew[i]['sort'] = i;
      }
    }

    setData(attachmentsNew);

    setTimeout(async function(){
      await handleCancel(e);
    }, 500);

    return false;

  }


  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDeleteModal([]);
    return false;

  }


  return (

    <div id="messages-modal" className={"uk-modal" + modalStateClass} data-uk-modal style={{display: 'block'}}>
      <div className="uk-modal-dialog">
        <form id="hierModalForm" className="uk-form-stacked" onSubmit={handleSubmit}>
          <button className="uk-modal-close-default" data-uk-close type="button" onClick={handleCancel}></button>
          <div className="uk-modal-header">
            <h3 className="">Вы действительно хотите удалить вложение товара допродаж?</h3>
          </div>
          <div className="uk-modal-footer uk-text-right">
            <button className="uk-button uk-button-default" type="button" onClick={handleCancel}>Отмена</button>
            <button className="uk-button uk-button-danger custom-button-danger" type="submit">Удалить</button>
          </div>
        </form>
      </div>
    </div>

  )
}

export default AttachmentsDeleteModalScreen;
