import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { NotificationsContext } from '../../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../../components/app/contexts/ApiContext';

const headings = {
  'create': 'Создать пользователя',
  'update': 'Редактировать пользователя',
}


function UserModalScreen(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const userId = props.userId;

  const users = props.users;

  var tempLogin = '';
  var tempBuyerId = '';
  var tempRole = 'user';
  if(userId > 0){
    users.forEach((item, i) => {
      if(item.id == userId){
        tempLogin = item.login;
        tempBuyerId = item.buyer_id;
        tempRole = item.role;
      }
    });
  }

  const [login, setLogin] = useState(tempLogin);
  const [password, setPassword] = useState('');
  const [buyerId, setBuyerId] = useState(tempBuyerId);
  const [role, setRole] = useState(tempRole);
  const [modal, setModal] = props.modal;
  const getData = props.getData;

  const rolesMap = props.rolesMap;

  const heading = props.userId > 0 ? headings.update : headings.create;

  var modalStateClass = ' uk-open';


  const handleInputChange = (e) => {
    var {id, value} = e.target;

    if(id === 'login'){
      setLogin(value);
    }

    if(id === 'password'){
      setPassword(value);
    }

    if(id === 'buyerId'){
      setBuyerId(value);
    }

    if(id === 'role'){
      setRole(value);
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(userId > 0){
      const response = await api.users.update(
        userId,
        login,
        password,
        buyerId,
        role
      );

      if(response.status === true){
        notifications.users.notification('user_updated');

        setTimeout(async function(){
          await getData();
          await handleCancel(e);
        }, 2500);
      } else {
        notifications.users.notification(response.error);
      }


    } else {

      const response = await api.users.create(
        login,
        password,
        buyerId,
        role
      );

      if(response.status === true){
        notifications.users.notification('user_created');

        setTimeout(async function(){
          await getData();
          await handleCancel(e);
        }, 2500);
      } else {
        notifications.users.notification(response.error);
      }

    }

    return false;
  }


  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setModal([]);
    return false;
  }


  function renderRoles(){
    var result = [];

    Object.keys(rolesMap).forEach((item, i) => {
      if(item != 'owner'){
        result.push(
          <option key={String('role-id-').concat(item)} value={item}>{rolesMap[item]}</option>
        );
      }
    });


    return result;
  }


  return (

  <div id="users-modal" className={"uk-modal" + modalStateClass} data-uk-modal style={{display: 'block'}}>
    <div className="uk-modal-dialog">
      <form id="hierModalForm" className="uk-form-stacked" onSubmit={handleSubmit}>
        <button className="uk-modal-close-default" data-uk-close type="button" onClick={handleCancel}></button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">{heading}</h2>
        </div>
        <div className="uk-modal-body">

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="role">Роль:</label>
            <div className="uk-form-controls">
              <select
                id="role"
                name="role"
                value={role}
                onChange={handleInputChange}
                className="uk-select"
                required={true}
              >
                {renderRoles()}
              </select>
            </div>
          </div>


          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="login">Логин:</label>
            <div className="uk-form-controls">
              <input
                id="login"
                name="login"
                value={login}
                onChange={handleInputChange}
                className="uk-input"
                placeholder="user_1"
                required={true}
              />
            </div>
          </div>

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="password">Пароль:</label>
            <div className="uk-form-controls">
              <input
                id="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                className="uk-input"
                placeholder={userId > 0 ? "Оставьте пустым, если не было изменений" : "********"}
                required={userId > 0 ? false : true}
              />
            </div>
          </div>

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="buyerId">Buyer ID:</label>
            <div className="uk-form-controls">
              <input
                id="buyerId"
                name="buyerId"
                value={buyerId}
                onChange={handleInputChange}
                className="uk-input"
                placeholder="user_1"
              />
            </div>
          </div>

        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default" type="button" onClick={handleCancel}>Отмена</button>
          <button className="uk-button uk-button-primary custom-button-success" type="submit">Сохранить</button>
        </div>
      </form>
    </div>
  </div>

  )
}

export default UserModalScreen;
