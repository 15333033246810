import { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";

import filterOrderByIcon from '../../../assets/img/filter-left.svg';
import filterOrderIcon from '../../../assets/img/arrow-down-up.svg';
import filterTypeIcon from '../../../assets/img/signpost.svg';
import filterPixelIcon from '../../../assets/img/facebook.svg';
import filterResponseCodeIcon from '../../../assets/img/check-all.svg';

import {renderDateTime, renderDateTimestamp, renderDateTimestampDate, roundFloat} from '../../../components/app/Functions';


function ServerLogsTableFilter(props){

  const params = useParams();
  const pixel = params.pixel ? params.pixel : false;

  const [filterOrderBy, setFilterOrderBy] = props.filterOrderBy;
  const [filterOrder, setFilterOrder] = props.filterOrder;
  const [filterSearch, setFilterSearch] = props.filterSearch;
  const [filterType, setFilterType] = props.filterType;
  const [filterPixel, setFilterPixel] = props.filterPixel;
  const [filterResponseCode, setFilterResponseCode] = props.filterResponseCode;
  const [filterCreated, setFilterCreated] = props.filterCreated;
  const [filterCreatedDate, setFilterCreatedDate] = useState('');
  const [filterPage, setFilterPage] = props.filterPage;

  const typesMeta = props.typesMeta;
  const pixelsMeta = props.pixelsMeta;
  const responseCodesMeta = props.responseCodesMeta;

  const getData = props.getData;

  const handleFilterChange = async (e) => {
    e.preventDefault();

    var {id, value} = e.target;

    if(id == 'filterOrderBy'){
      await setFilterPage(1);
      await setFilterOrderBy(value);
    }

    if(id == 'filterOrder'){
      await setFilterPage(1);
      await setFilterOrder(value);
    }

    if(id == 'filterSearch'){
      await setFilterPage(1);
      await setFilterSearch(value);
    }

    if(id == 'filterType'){
      await setFilterPage(1);
      await setFilterType(value);
    }

    if(id == 'filterPixel'){
      await setFilterPage(1);
      await setFilterPixel(value);
    }

    if(id == 'filterResponseCode'){
      await setFilterPage(1);
      await setFilterResponseCode(value);
    }

    if(id == 'filterCreated'){
      await setFilterPage(1);
      await setFilterCreatedDate(value);
      await setFilterCreated(renderDateTimestampDate(value));
    }

  }


  function renderTypesMetaList(){

    var options = [];

    options.push(
      <option key="type-init" value="">-- Тип события --</option>
    );
    Object.keys(typesMeta).forEach((item, i) => {
      if(!item){
        return;
      }

      options.push(
        <option key={"type-" + i} value={item}>{item}</option>
      );
    });

    return options;
  }


  function renderPixelsMetaList(){

    var options = [];

    options.push(
      <option key="pixel-init" value="">-- Пиксель --</option>
    );
    Object.keys(pixelsMeta).forEach((item, i) => {

      if(!item){
        return;
      }

      options.push(
        <option key={"pixel-" + i} value={item}>{item}</option>
      );
    });

    return options;
  }


  function renderResponseCodesMetaList(){

    var options = [];

    options.push(
      <option key="response-code-init" value="">-- Код ответа --</option>
    );
    Object.keys(responseCodesMeta).forEach((item, i) => {

      if(!item){
        return;
      }

      options.push(
        <option key={"response-code-" + i} value={item}>{item}</option>
      );
    });

    return options;
  }


  return (
    <div className="uk-flex-right uk-grid-collapse server-logs-table-filter" data-uk-grid>

      <div id="filterSearchDiv">
        <form className="uk-search uk-search-default">
          <span data-uk-search-icon></span>
          <input
            className="uk-search-input"
            type="search"
            id="filterSearch"
            name="filterSearch"
            placeholder="Поиск"
            value={filterSearch}
            onChange={handleFilterChange}
          />
        </form>
      </div>


      <div id="filterCreatedDiv">
        <input
          className="uk-input"
          type="date"
          id="filterCreated"
          name="filterCreated"
          placeholder="Дата"
          value={filterCreatedDate}
          onChange={handleFilterChange}
        />
      </div>


      <div id="filterTypeDiv">
        <picture>
          <img src={filterTypeIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterType"
          name="filterType"
          value={filterType}
          onChange={handleFilterChange}
        >
          {renderTypesMetaList()}
        </select>
      </div>

      {
        pixel == false && (

          <div id="filterPixelDiv">
            <picture>
              <img src={filterPixelIcon} />
            </picture>
            <select
              className="uk-select"
              id="filterPixel"
              name="filterPixel"
              value={filterPixel}
              onChange={handleFilterChange}
            >
              {renderPixelsMetaList()}
            </select>
          </div>
        )
      }


      <div id="filterResponseCodeDiv">
        <picture>
          <img src={filterResponseCodeIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterResponseCode"
          name="filterResponseCode"
          value={filterResponseCode}
          onChange={handleFilterChange}
        >
          {renderResponseCodesMetaList()}
        </select>
      </div>


      <div id="filterOrderByDiv">
        <picture>
          <img src={filterOrderByIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrderBy"
          name="filterOrderby"
          value={filterOrderBy}
          onChange={handleFilterChange}
        >
          <option value="created">Создан</option>
          <option value="type">Тип события</option>
          <option value="pixel">Пиксель</option>
          <option value="response_code">Код ответа</option>
          <option value="request">Запрос</option>
          <option value="response">Ответ</option>
          <option value="event_id">Event ID</option>
        </select>
      </div>


      <div id="filterOrderDiv">
        <picture>
          <img src={filterOrderIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrder"
          name="filterOrder"
          value={filterOrder}
          onChange={handleFilterChange}
        >
          <option value="desc">По убыванию</option>
          <option value="asc">По возрастанию</option>
        </select>
      </div>

    </div>
  );
}

export default ServerLogsTableFilter;
