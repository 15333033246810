import AbstractNotifications from './AbstractNotifications';

class SettingsNotifications extends AbstractNotifications {
  messages = {
    'setting_updated': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },

    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    },
  }
}

export default SettingsNotifications;
