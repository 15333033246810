import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../../components/app/Functions';


function ServerLogsTablePosition(props){

  const item = props.item;

  const request = JSON.stringify(item.request, null, 2);
  const response = JSON.stringify(item.response, null, 2);

  const responseCodeColor = item.response_code == 200 ? 'green' : 'red';
  const responseCode = (<span style={{color: responseCodeColor}}>{item.response_code}</span>);
  return (
    <tr>
      <td className="uk-text-meta">
        {renderDateTime(item.created)}
      </td>
      <td className="uk-text-meta">
        <u>{item.type}</u>
      </td>
      <td>
        {item.pixel}
      </td>
      <td>
        <small>{item.event_id}</small>
      </td>
      <td>
        <textarea defaultValue={request} readOnly={true} className="uk-textarea"></textarea>
      </td>
      <td>
        <textarea defaultValue={response} readOnly={true} className="uk-textarea"></textarea>
      </td>
      <td className="uk-text-center">
        {responseCode}
      </td>
    </tr>
  );
}

export default ServerLogsTablePosition;
