import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../components/app/Functions';


function SitesTablePosition(props){

  const item = props.item;
  const handleSingleSelection = props.handleSingleSelection;
  const openModal = props.openModal;

  const typesMap = props.typesMap;
  const upsellsCategoriesMeta = props.upsellsCategoriesMeta;
  const type = typesMap[item.type];

  var upsellsCategories = [];

  if(item.upsells && item.upsells.length > 0){
    item.upsells.forEach((upsell, i) => {
      if(upsell.type == 'category'){

        const upsellCategory = upsellsCategoriesMeta.filter((upsellsCategory, j) => {
          return upsellsCategory.id == upsell.id;
        });

        const upsellCategoryName = upsellCategory.length > 0 ? upsellCategory[0].name : false;

        if(upsellCategoryName){
          upsellsCategories.push(
            <div key={String("sites-upsells_category-").concat(i)} className="sites-upsell-category">
              {upsellCategoryName}
            </div>
          );
        }

      }
    });
  }



  return (
    <tr key={item.id}>
      <td>
        <input type="checkbox" name="deleteItems[]" value={item.id} onChange={handleSingleSelection}/>
      </td>
      <td>
        {item.id}
      </td>
      <td>
        <NavLink className="uk-text-center custom-link" to={['', 'sites', item.id].join('/')}>{item.name}</NavLink>
      </td>
      <td className="uk-text-center sites-url">
        {item.internal_name} {(item.url) && (<NavLink to={item.url} target="_blank" className="uk-icon" uk-icon="link"></NavLink>)}
      </td>
      <td className="uk-text-center">
        {item.crm.buyer_id}
      </td>
      <td className="uk-text-center uk-text-meta">
        {type}
      </td>
      <td className="uk-text-center">
        <b>{roundFloat(item.default.price)}</b>
      </td>
      {/*<td className="uk-text-center">
        <u>{item.default.external_id}</u> ✕ {item.default.quantity}
      </td>*/}
      <td className="uk-text-center">
        <small>{upsellsCategories}</small>
      </td>
      <td className="uk-text-meta">
        {renderDateTime(item.created)}
      </td>
      <td className="uk-text-meta">
        {renderDateTime(item.modified)}
      </td>
      {/*<td className="uk-text-center">
        <button
          className="uk-icon-button"
          uk-icon="download"
          onClick={openModal}
          data-uk-tooltip="Файлы интеграции"
        ></button>
      </td>*/}
    </tr>
  );
}

export default SitesTablePosition;
