import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { NotificationsContext } from '../../../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../../../components/app/contexts/ApiContext';

const headings = {
  'create': 'Создать Facebook пиксель/токен',
  'update': 'Редактировать Facebook пиксель/токен',
}


function PixelsTokenModalScreen(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const pixelsTokenId = props.pixelsTokenId;

  const data = props.data;

  var tempPixel = '';
  var tempAccessToken = '';
  var tempComment = '';
  if(pixelsTokenId > 0){
    data.forEach((item, i) => {
      if(item.id == pixelsTokenId){
        tempPixel = item.pixel;
        tempAccessToken = item.access_token;
        tempComment = item.comment;
      }
    });
  }

  const [pixel, setPixel] = useState(tempPixel);
  const [accessToken, setAccessToken] = useState(tempAccessToken);
  const [comment, setComment] = useState(tempComment);
  const [error, setError] = useState('');
  const [modal, setModal] = props.modal;
  const getData = props.getData;

  const heading = props.pixelsTokenId > 0 ? headings.update : headings.create;

  var modalStateClass = ' uk-open';


  const handleInputChange = (e) => {
    var {id, value} = e.target;

    if(id === 'pixel'){
      setPixel(value);
    }

    if(id === 'accessToken'){
      setAccessToken(value);
    }

    if(id === 'comment'){
      setComment(value);
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(pixelsTokenId > 0){
      const response = await api.facebook.pixelsTokens.update(
        pixelsTokenId,
        pixel,
        accessToken,
        comment
      );

      if(response.status === true){
        notifications.facebook.pixelsTokens.notification('facebook_pixels_token_updated');
        await setError('');

        setTimeout(async function(){
          await getData();
          await handleCancel(e);
        }, 2500);
      } else {
        if(response.error.indexOf('invalid_server_event') !== -1){
          const errorParts = response.error.split('::');
          notifications.facebook.pixelsTokens.notification(errorParts[0]);
          await setError(errorParts[1]);
        } else {
          notifications.facebook.pixelsTokens.notification(response.error);
        }
      }


    } else {

      const response = await api.facebook.pixelsTokens.create(
        pixel,
        accessToken,
        comment
      );

      if(response.status === true){
        notifications.facebook.pixelsTokens.notification('facebook_pixels_token_created');
        await setError('');

        setTimeout(async function(){
          await getData();
          await handleCancel(e);
        }, 2500);
      } else {
        if(response.error.indexOf('invalid_server_event') !== -1){
          const errorParts = response.error.split('::');
          notifications.facebook.pixelsTokens.notification(errorParts[0]);
          await setError(errorParts[1]);
        } else {
          notifications.facebook.pixelsTokens.notification(response.error);
        }
      }

    }

    return false;
  }


  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setModal([]);
    return false;
  }


  function renderError(){
    if(error == ''){
      return;
    }

    const errorParams = JSON.stringify(JSON.parse(error), null, 2);
    const result = (
      <div className="uk-alert-danger uk-padding-small">
        <p>Ответ <i>Facebook Conversions API</i> (текст ошибки):</p>
        <pre>
          {errorParams}
        </pre>
      </div>
    );


    return result;
  }


  return (

  <div id="users-modal" className={"uk-modal" + modalStateClass} data-uk-modal style={{display: 'block'}}>
    <div className="uk-modal-dialog">
      <form id="hierModalForm" className="uk-form-stacked" onSubmit={handleSubmit}>
        <button className="uk-modal-close-default" data-uk-close type="button" onClick={handleCancel}></button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">{heading}</h2>
        </div>
        <div className="uk-modal-body">

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="pixel">Пиксель:</label>
            <div className="uk-form-controls">
              <input
                id="pixel"
                name="pixel"
                value={pixel}
                onChange={handleInputChange}
                className="uk-input"
                placeholder="670080703004000"
                required={true}
              />
            </div>
          </div>


          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="accessToken">Access Token:</label>
            <div className="uk-form-controls">
              <textarea
                id="accessToken"
                name="accessToken"
                value={accessToken}
                onChange={handleInputChange}
                className="uk-textarea"
                placeholder="Пожалуйста, вставьте сгенерированный в Facebook Events Manager токен доступа конкретно для введенного выше пикселя"
                required={true}
              ></textarea>
            </div>
          </div>

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="comment">Комментарий:</label>
            <div className="uk-form-controls">
              <textarea
                id="comment"
                name="comment"
                value={comment}
                onChange={handleInputChange}
                className="uk-textarea"
                placeholder="Поле для заметок"
              ></textarea>
            </div>
          </div>

          {renderError()}

        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default" type="button" onClick={handleCancel}>Отмена</button>
          <button className="uk-button uk-button-primary custom-button-success" type="submit">Сохранить</button>
        </div>
      </form>
    </div>
  </div>

  )
}

export default PixelsTokenModalScreen;
