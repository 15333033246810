import React from 'react';

const Breadcrumbs = ({children}) => {
  return (
    <ul className="uk-breadcrumb">
      {children}
    </ul>
  )
}

export default Breadcrumbs;
