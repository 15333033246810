import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

function Category(props){

  const [categories, setCategories] = props.categories;
  const categoriesMeta = props.categoriesMeta;
  const index = props.index;


  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id == "upsellsCategories[" + index + "]"){

      var categoriesNew = [...categories];
      categoriesNew[index] = value ? parseInt(value) : '';

      setCategories(categoriesNew);
    }
  }


  const handleDeleteCategory = (e) => {

    var categoriesNew = [...categories];
    categoriesNew = categoriesNew.filter((elem, i) => {
      return i !== index;
    });

    setCategories(categoriesNew);
  }


  const handleArrowUp = (e) => {
    if(index === 0){
      return false;
    }

    var categoriesNew = [...categories];
    var temp = categoriesNew[index - 1];
    categoriesNew[index - 1] = categoriesNew[index];
    categoriesNew[index] = temp;

    setCategories(categoriesNew);
  }

  const handleArrowDown = (e) => {
    if( (index + 1) === categories.length){
      return false;
    }

    var categoriesNew = [...categories];
    var temp = categoriesNew[index + 1];
    categoriesNew[index + 1] = categoriesNew[index];
    categoriesNew[index] = temp;

    setCategories(categoriesNew);
  }


  function renderArrows(){

    var arrowUp = null;
    var arrowDown = null;

    if(index !== 0){
      arrowUp = (
        <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-up" onClick={handleArrowUp}></span>
      );
    }

    if( (index + 1) !== categories.length){
      arrowDown = (
        <span className="uk-margin-small-right uk-text-center" uk-icon="icon: chevron-down" onClick={handleArrowDown}></span>
      );
    }

    return (
      <>
        {arrowUp}
        {arrowDown}
      </>
    );
  }


  function renderOptions(){

    var options = [];
    options.push(
      <option key={"upsell-category-option-" + index + "-default"} value="">-- Категория --</option>
    );

    categoriesMeta.forEach((item, i) => {
      if(categories[index] == item.id || !categories.includes(item.id)){
        options.push(
          <option key={"upsell-category-option-" + index + "-" + i} value={item.id}>{item.name}</option>
        );
      }
    });

    return options;
  }


  return (
    <div className="uk-card uk-card-default uk-card-body uk-card-small">

      <div className="uk-flex uk-flex-middle">

        {/*<div className="uk-width-1-6">
          {renderArrows()}
        </div>*/}

        <div className="uk-width-5-6 uk-margin-right" id={"categoryBox-" + index}>
          <div className="uk-form-controls">
            <select
              id={"upsellsCategories[" + index + "]"}
              className="uk-select"
              name={"upsellsCategories[" + index + "]"}
              onChange={handleInputChange}
              value={categories[index]}
              required={true}
            >
              {renderOptions()}
            </select>
          </div>
        </div>

        <div className="uk-width-1-6 uk-text-center">
          <span className="uk-margin-small-right uk-text-center trash-button" data-category-index={index} uk-icon="icon: trash" onClick={handleDeleteCategory}></span>
        </div>

      </div>

    </div>
  );
}

export default Category;
