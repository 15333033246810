import {useEffect, useState} from "react";
import useAuthToken from "../../components/app/AuthToken";
import useAuthLogin from "../../components/app/AuthLogin";

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';


function DashboardAdmin() {

  return (
    <div className="uk-container">

      <h2 className="uk-text-center">Добро пожаловать!</h2>

      <hr/>

    </div>
  )
}

export default DashboardAdmin;
