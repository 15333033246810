import { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import {renderDateTime} from '../../../../components/app/Functions';
import AttachmentsModalScreen from './AttachmentsModalScreen';
import AttachmentsDeleteModalScreen from './AttachmentsDeleteModalScreen';


function AttachmentsGrid(props) {

  const [data, setData] = props.attachments;

  const [modal, setModal] = useState([]);
  const [deleteModal, setDeleteModal] = useState([]);

  const openModal = (e) => {
    e.preventDefault();

    var index = e.currentTarget.getAttribute('data-attachment-index');

    setModal(
      <AttachmentsModalScreen
        data={[data, setData]}
        index={index}
        modal={[modal, setModal]}
      />
    );
  }


  const handleArrowLeft = async (e) => {
    var target = e.currentTarget;
    var attachmentIndex = parseInt(target.getAttribute('data-attachment-index'));

    var attachmentPrev = data.at(attachmentIndex - 1);
    attachmentPrev.sort = attachmentIndex;
    var attachmentCur = data.at(attachmentIndex);
    attachmentCur.sort = attachmentIndex - 1;

    var attachmentsNew = [...data];
    attachmentsNew[attachmentIndex - 1] = attachmentCur;
    attachmentsNew[attachmentIndex] = attachmentPrev;

    setData(attachmentsNew);
  }


  const handleArrowRight = async (e) => {
    var target = e.currentTarget;
    var attachmentIndex = parseInt(target.getAttribute('data-attachment-index'));

    var nextIndex = (attachmentIndex + 1) === data.length ? -1 : attachmentIndex + 1;
    var attachmentNext = data.at(nextIndex);
    attachmentNext.sort = attachmentIndex;
    var attachmentCur = data.at(attachmentIndex);
    attachmentCur.sort = nextIndex;

    var attachmentsNew = [...data];
    attachmentsNew[attachmentIndex] = attachmentNext;
    attachmentsNew[nextIndex] = attachmentCur;

    setData(attachmentsNew);

  }


  function renderGrid(){

    var result = [];

    data.forEach((item, i) => {

      var arrowLeft = (
        <span
          className="uk-text-center attachment-arrow-left" style={{width: '20px', display: 'inline-block'}}
        ></span>
      );

      if(i !== 0){
        arrowLeft = (
          <span
            className="uk-text-center attachment-arrow-left"
            uk-icon="icon: chevron-left"
            data-attachment-index={i}
            onClick={handleArrowLeft}
          ></span>
        );
      }

      var arrowRight = (
        <span
          className="uk-text-center attachment-arrow-right" style={{width: '20px', display: 'inline-block'}}
        ></span>
      );

      if((i + 1) !== data.length){
        arrowRight = (
          <span
            className="uk-text-center attachment-arrow-right"
            uk-icon="icon: chevron-right"
            data-attachment-index={i}
            onClick={handleArrowRight}
          ></span>
        );
      }

      var comment = item.comment !== 'null' ? item.comment : '';

      result.push(
      <li key={"attachment-" + i}>
        <div className="uk-width-1-1 uk-height-1-1">
          <div className="uk-card uk-card-hover uk-card-default attachments-card">
            <div className="uk-card-header uk-padding-small uk-flex uk-flex-middle">
              <div data-uk-lightbox>
                <NavLink to={item.media} data-caption={comment}>
                  <picture>
                    <img src={item.media} />
                  </picture>
                </NavLink>
              </div>
            </div>
            <div className="uk-card-footer uk-padding-small uk-text-center">
              {arrowLeft}
              <NavLink target="_blank" download={String('attachment-').concat(item.id)} className="uk-icon uk-icon-button" data-uk-icon="icon: download" to={item.media}></NavLink>
              <button className="uk-icon uk-icon-button" onClick={openModal} data-uk-icon="icon: pencil" data-attachment-index={i}></button>
              <button className="uk-icon uk-icon-button" onClick={openDeleteModal} data-uk-icon="icon: trash" data-attachment-index={i}></button>
              {arrowRight}
            </div>
          </div>
        </div>
      </li>
      );
    });

    if(result.length == 0){
      result.push(
        <div key="attachement-none">
          Нет данных
        </div>
      )
    }

    return result;
  }


  const openDeleteModal = async (e) => {

    var index = e.currentTarget.getAttribute('data-attachment-index');

    setDeleteModal(
      <AttachmentsDeleteModalScreen
        data={[data, setData]}
        index={index}
        deleteModal={[deleteModal, setDeleteModal]}
      />
    );
  }


  return (

    <>
      <div className="uk-grid-collapse" data-uk-grid>

        <div className="uk-width-1-1">
          <div className="uk-text-center">
            <button className="uk-button uk-button-primary custom-button-success" onClick={openModal} data-attachment-index={-1}>Добавить</button>
          </div>
        </div>

      </div>

      {/*<div className="uk-grid" data-uk-height-match="target: > div > .uk-card * " data-uk-grid>*/}
      <div data-uk-slider>
        <div className="uk-position-relative">
          <div className="uk-slider-container attachments-slider-container">
            <ul className="uk-grid uk-slider-items uk-child-width-1-5 product-attachments-slider" data-uk-height-match="target: > li > div > .uk-card .uk-card-header " data-uk-grid>
              {renderGrid()}
            </ul>
          </div>

          <div className="uk-hidden@s uk-light">
            <a className="uk-position-center-left uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
            <a className="uk-position-center-right uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
          </div>

          <div className="uk-visible@s">
            <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
            <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
          </div>

        </div>
        {/*<ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>*/}
      </div>

      {modal}
      {deleteModal}

    </>

  )
}

export default AttachmentsGrid;
