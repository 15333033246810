import AbstractApi from "./AbstractApi";

class AdminApi extends AbstractApi  {

  // Authorization
  checkCredentialsMethod = 'dashboard/admin/authorization';

  // AdminSettings
  changePasswordMethod = 'dashboard/admin/change_password';

  /*--------------------------------------------------------------------------*/
  /* Authorization */

  async checkCredentials(login, password) {

    this.requestMethod = this.checkCredentialsMethod;

    this.requestData = new FormData();
    this.requestData.append('login', login);
    this.requestData.append('password', password);

    return await this.request();
  }


  /*--------------------------------------------------------------------------*/
  /* AdminSettings */

  async changePassword(oldPassword, newPassword) {

    this.requestMethod = this.changePasswordMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('old_password', oldPassword);
    this.requestData.append('new_password', newPassword);

    return await this.request();
  }

}

export default AdminApi;
