import icon from '../../assets/img/door.svg';
import  { NavLink, Link, useParams } from "react-router-dom";

function Home() {

  return (
    <div className="uk-container">

      {/*<div className="uk-padding uk-text-center">
        <img width="20%" src={icon} />
      </div>

      <h1 className="uk-text-center"><NavLink to="/login">Вход</NavLink></h1>*/}

    </div>
  );


}

export default Home;
