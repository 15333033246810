import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

function TablePagination(props){

  const [filterPage, setFilterPage] = props.filterPage;
  const [filterTotalPages, setFilterTotalPages] = props.filterTotalPages;

  const getData = props.getData;


  const handlePageChange = async (e) => {
    e.preventDefault();

    var nextPage = e.currentTarget.getAttribute('data-page');

    const topPagination = document.querySelector('.pagination');
    topPagination.scrollIntoView({behavior: 'smooth'});
    setFilterPage(nextPage);

    return false;
  };


  function renderPagination(){

    if(filterTotalPages <= 1){
      return [];
    }

    var pages = [];

    var pageOffset = 10;
    var pageStart = (parseInt(filterPage) - pageOffset) > 0 ? (parseInt(filterPage) - pageOffset) : 1;
    var pageFinish = (parseInt(filterPage) + pageOffset) <= filterTotalPages ? (parseInt(filterPage) + pageOffset) : filterTotalPages;


    for (var i = pageStart; i <= pageFinish; i++) {

      var active = parseInt(filterPage) == parseInt(i) ? "uk-active" : "";

      pages.push(
        <li key={"page-" + i} className={active}>
          <NavLink to="#" onClick={handlePageChange} data-page={i}>{i}</NavLink>
        </li>
      );
    }

    // Prepend first page
    if(pageStart > 1){

      var dots = pageStart > 2;

      var firstSpecialPage = [];
      firstSpecialPage.push(
        <li key={"page-" + 1}>
          <NavLink to="#" onClick={handlePageChange} data-page={1}>{1}</NavLink>
        </li>
      );

      if(dots){
        firstSpecialPage.push(
          <li key={"page-" + 'prevDots'}>
            ...
          </li>
        );
      }

      pages = firstSpecialPage.concat(pages);
    }

    // Append last page
    if(pageFinish < filterTotalPages){

      var dots = pageFinish < (filterTotalPages - 1);

      if(dots){
        pages.push(
          <li key={"page-" + 'nextDots'}>
            ...
          </li>
        );
      }

      pages.push(
        <li key={"page-" + filterTotalPages}>
          <NavLink to="#" onClick={handlePageChange} data-page={filterTotalPages}>{filterTotalPages}</NavLink>
        </li>
      );
    }

    return (
      <div className="pagination uk-margin">
        <div className="uk-text-center">Страницы</div>
        <ul className="uk-pagination uk-flex-center uk-margin-remove">
          {pages}
        </ul>
      </div>
    );
  }


  return (
    <>
      {renderPagination()}
    </>
  );
}

export default TablePagination;
