import { useState, useContext } from "react";
import useAuthToken from '../../components/app/AuthToken';
import useAuthLogin from '../../components/app/AuthLogin';
import useAuthRole from '../../components/app/AuthRole';

import { ApiContext } from '../../components/app/contexts/ApiContext';


function LoginAdmin() {

  const api = useContext(ApiContext);

  const {authToken, setAuthToken} = useAuthToken();
  const {authLogin, setAuthLogin} = useAuthLogin();
  const {authRole, setAuthRole} = useAuthRole();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loggedError, setLoggedError] = useState(false);

  if(authToken != null){
    window.location.href = '/';
  }

  const handleInputChange = (e) => {
    const {id, value} = e.target;

    if(id === 'login'){
      setLogin(value);
    }

    if(id === 'password'){
      setPassword(value);
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await api.admin.checkCredentials(
      login,
      password
    );

    if( response.status == true ){

      setAuthToken(response.token);
      setAuthLogin(login);
      setAuthRole(response.role);
      setLoggedError(false);

      window.location.href = '/';

    } else {
      setLoggedError(true);
    }

  }

  return (
    <div className="uk-container uk-container-xsmall" id='screen-1'>

      <h1 className="uk-heading-line uk-text-center">
        <span>Вход</span>
      </h1>

      <h1 className="uk-heading-divider"></h1>

      <form id="loginForm" className="uk-form-vertical uk-margin-large" onSubmit={handleSubmit}>

        <legend className="uk-legend uk-text-center">Ваши данные для доступа:</legend>

        <div className="uk-margin" id="loginBox">
          <label className="uk-form-label uk-text-center" htmlFor="login">Логин:</label>
          <div className="uk-form-controls">
            <input id="login"
                   className="uk-input"
                   name="login"
                   type="text"
                   placeholder="manager@test.com"
                   required
                   onChange={handleInputChange}
                   value={login}
            />
          </div>
        </div>

        <div className="uk-margin" id="passwordBox">
          <label className="uk-form-label uk-text-center" htmlFor="password">Пароль:</label>
          <div className="uk-form-controls">
            <input id='password'
                   className="uk-input"
                   name="password"
                   type="password"
                   placeholder="**********"
                   required
                   onChange={handleInputChange}
                   value={password}
            />
          </div>
        </div>

        <div className="login-alert uk-alert-danger" uk-alert="true" hidden={!loggedError}>
          <p>Пожалуйста, проверьте ваши данные</p>
        </div>

        <button id='submitLogin' type="submit" className="uk-button uk-button-primary uk-align-center custom-button-info" onSubmit={handleSubmit}>Вход</button>

      </form>
    </div>
  )
}

export default LoginAdmin;
