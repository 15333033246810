import AbstractApi from "../AbstractApi";

class ServerLogsApi extends AbstractApi  {

  // Logs
  getFilterMethod = 'dashboard/facebook/server_logs/get';
  getMethod = 'dashboard/facebook/server_logs/{:id}/get';
  metaMethod = 'dashboard/facebook/server_logs/meta';

  /*--------------------------------------------------------------------------*/
  /* Logs */

  async get(id = false, filterOrderBy = 'created', filterOrder = 'desc', filterSearch = false, filterType = false, filterPixel = false, filterResponseCode = false, filterCreated = false, paginationPage = 1, paginationLimit = 100) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;


      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      if(filterType){
        this.requestData.append('filter[type]', filterType);
      }

      if(filterPixel){
        this.requestData.append('filter[pixel]', filterPixel);
      }

      if(filterResponseCode){
        this.requestData.append('filter[response_code]', filterResponseCode);
      }

      if(filterCreated){
        this.requestData.append('filter[created]', filterCreated);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async meta() {

    this.requestMethod = this.metaMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }


}

export default ServerLogsApi;
