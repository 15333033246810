import AdminNotifications from './modules/AdminNotifications';
import UsersNotifications from './modules/UsersNotifications';

import CategoriesNotifications from './modules/upsells/CategoriesNotifications';
import UpsellsNotifications from './modules/UpsellsNotifications';
import ImagesNotifications from './modules/upsells/ImagesNotifications';

import SitesNotifications from './modules/SitesNotifications';

import FilesNotifications from './modules/sites/FilesNotifications';

import SettingsNotifications from './modules/SettingsNotifications';

import PixelsTokensNotifications from './modules/facebook/PixelsTokensNotifications';

import SitesCataloguesNotifications from './modules/SitesCataloguesNotifications';

import FilesCataloguesNotifications from './modules/sitesCatalogues/FilesNotifications';



class Notifications {

  constructor() {
    this.admin = new AdminNotifications();
    this.users = new UsersNotifications();

    this.upsells_categories = new CategoriesNotifications();

    this.upsells = new UpsellsNotifications();
    this.upsell = {};
    this.upsell.images = new ImagesNotifications();

    this.sites = new SitesNotifications();

    this.site = {};
    this.site.files = new FilesNotifications();

    this.settings = new SettingsNotifications();

    this.facebook = {};
    this.facebook.pixelsTokens = new PixelsTokensNotifications();

    this.sitesCatalogues = new SitesCataloguesNotifications();

    this.siteCatalogue = {};
    this.siteCatalogue.files = new FilesCataloguesNotifications();

  }
}

export default Notifications;
