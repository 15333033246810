import AdminApi from "./modules/AdminApi";
import UsersApi from "./modules/UsersApi";
import LogsApi from "./modules/LogsApi";

import CategoriesApi from "./modules/upsells/CategoriesApi";
import UpsellsApi from "./modules/UpsellsApi";
import ImagesApi from "./modules/upsells/ImagesApi";

import SitesApi from "./modules/SitesApi";

import FilesApi from "./modules/sites/FilesApi";

import SettingsApi from "./modules/SettingsApi";

import PixelsTokensApi from "./modules/facebook/PixelsTokensApi";
import ServerLogsApi from "./modules/facebook/ServerLogsApi";

import SitesCataloguesApi from "./modules/SitesCataloguesApi";

import FilesCataloguesApi from "./modules/sitesCatalogues/FilesApi";



class ApiHandler {

  constructor(authToken){

    this.admin = new AdminApi(authToken);
    this.users = new UsersApi(authToken);
    this.logs = new LogsApi(authToken);

    this.upsells_categories = new CategoriesApi(authToken);

    this.upsells = new UpsellsApi(authToken);
    this.upsell = {};
    this.upsell.images = new ImagesApi(authToken);

    this.sites = new SitesApi(authToken);
    this.site = {};
    this.site.files = new FilesApi(authToken);

    this.settings = new SettingsApi(authToken);

    this.facebook = {};
    this.facebook.pixelsTokens = new PixelsTokensApi(authToken);
    this.facebook.serverLogs = new ServerLogsApi(authToken);

    this.sitesCatalogues = new SitesCataloguesApi(authToken);
    this.siteCatalogue = {};
    this.siteCatalogue.files = new FilesCataloguesApi(authToken);

  }

}

export default ApiHandler;
