import AbstractApi from "./AbstractApi";

class SettingsApi extends AbstractApi  {

  // Settings
  getFilterMethod = 'dashboard/settings/get';
  getMethod = 'dashboard/settings/{:id}/get';
  updateMethod = 'dashboard/settings/{:id}/update';

  /*--------------------------------------------------------------------------*/
  /* Settings */

  async get(id = false, filterOrderBy = 'id', filterOrder = 'asc', filterSearch = false, paginationPage = 1, paginationLimit = 50) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;

      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }


      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async update(id, value = false) {

    this.requestMethod = this.updateMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(value !== false){
      this.requestData.append('value', value);
    }

    return await this.request();
  }

}

export default SettingsApi;
