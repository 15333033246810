import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { renderDateTime, roundFloat } from '../../components/app/Functions';

const dashboardEntities = [
  'upsells', 'sites'
];

function LogsTablePosition(props){

  const item = props.item;
  const sectionsMeta = props.sectionsMeta;
  const sectionsMap = props.sectionsMap;

  const section = item.section in sectionsMap ? sectionsMap[item.section] : item.section;
  const params = JSON.stringify(item.params, null, 2);


  const entityLink = dashboardEntities.indexOf(item.section) !== -1 ? (<NavLink className="custom-link" to={['', item.section, item.entity_id].join('/')}>{item.entity_id}</NavLink>) : item.entity_id;

  return (
    <tr>
      <td className="uk-text-meta">
        {renderDateTime(item.created)}
      </td>
      <td>
        {item.user_id}
      </td>
      <td className="uk-text-meta">
        {section}
      </td>
      <td className="">
        <b>{entityLink}</b>
      </td>
      <td>
        <u>{item.message}</u>
      </td>
      <td>
        <textarea defaultValue={params} readOnly={true} className="uk-textarea"></textarea>
      </td>
    </tr>
  );
}

export default LogsTablePosition;
