import { useState } from 'react';

export default function useAuthToken() {
  const getToken = () => {
    var data = localStorage.getItem('adminAuthToken');
    if(data == 'null'){
      data = null;
    }
    return data;
  };

  const [authToken, setAuthToken] = useState(getToken());

  const setToken = token => {
    localStorage.setItem('adminAuthToken', token);
    setAuthToken(token);
  };

  return {
    setAuthToken: setToken,
    authToken
  }
}
