class AbstractApi {

  baseUri = process.env.REACT_APP_API_BASE_URI;

  authToken = null;

  requestMethod = false;
  requestData = null;

  constructor(authToken){
    this.authToken = authToken;
  }


  async request() {

    var response = await fetch(
      String(this.baseUri).concat(this.requestMethod),
      {
       method: 'POST',
       mode: 'cors',
       cache: 'no-cache',
       body: this.requestData
      }
    );

    var responseJSON = await response.json();

    return responseJSON;
  }
}

export default AbstractApi;
