import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { renderDateTimestamp, renderDateInputTime, roundFloat } from '../../../../../components/app/Functions';

const roundPrecision = 100000;

function Content(props){

  const type = props.type;
  const index = props.index;
  const subIndex = props.subIndex;

  const [formula, setFormula] = props.formula;


  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id === "formula[" + index + "][options][" + subIndex +"][from]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].from = value;
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][to]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].to = parseInt(value) ? parseInt(value) : '';

      if(formulaNew[index].options.length > (subIndex + 1)){
        if(parseInt(formulaNew[index].options[subIndex].from) < parseInt(value)){
          formulaNew[index].options[subIndex + 1].from = parseInt(value);
        } else {
          formulaNew[index].options[subIndex + 1].from = '';
        }
      }

      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][display][old]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].display.old = value;
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][display][new]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].display.new = value;
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][display][discount]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].display.discount = value;
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][price]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].price = value !== '' ? roundFloat(value, roundPrecision) : '';
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][price_crm]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].price_crm = value !== '' ? roundFloat(value, roundPrecision) : '';
      if(value !== ''){
        formulaNew[index].options[subIndex].price = roundFloat(value * 1000, roundPrecision);
      }
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][delivery]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].delivery = value !== '' ? roundFloat(value, roundPrecision) : '';
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][delivery_crm]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].delivery_crm = value !== '' ? roundFloat(value, roundPrecision) : '';
      if(value !== ''){
        formulaNew[index].options[subIndex].delivery = roundFloat(value * 1000, roundPrecision);
      }
      setFormula(formulaNew);
    }

    if(id === "formula[" + index + "][options][" + subIndex +"][external_id]"){
      var formulaNew = [...formula];
      formulaNew[index].options[subIndex].external_id = value.replace(' ', '');
      setFormula(formulaNew);
    }


  }


  function renderFromMin(){
    return formula[index].options.length > 0 ? parseInt(formula[index].options[formula[index].options.length - 1].to) : 0;
  }



  return (
    <>

    <div className="uk-grid-small" data-uk-grid>

      <div className="uk-width-1-2">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][from]"}>Количество от:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][from]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][from]"}
            type="number"
            min={formula[index].options[subIndex].from}
            step="1"
            max={formula[index].options[subIndex].from}
            onChange={handleInputChange}
            value={formula[index].options[subIndex].from}
            placeholder={'Заполните опцию выше'}
            required={true}
            readOnly={true}
          />
        </div>
      </div>

      <div className="uk-width-1-2">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][to]"}>Количество до (не включительно):</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][to]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][to]"}
            type="number"
            min={formula[index].options[subIndex].from + 1}
            step="1"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].to}
            placeholder={'Пустое, если бесконечность'}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][price_crm]"}>Цена за 1 штуку (CRM):</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][price_crm]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][price_crm]"}
            type="number"
            min="0"
            step="0.00001"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].price_crm}
            placeholder={'Для заказа CRM'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][delivery_crm]"}>Стоимость доставки (CRM):</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][delivery_crm]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][delivery_crm]"}
            type="number"
            min="0"
            step="0.00001"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].delivery_crm}
            placeholder={'Для заказа CRM'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][external_id]"}>Внешний ID (CRM):</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][external_id]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][external_id]"}
            type="text"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].external_id}
            placeholder={'Для заказа CRM'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-1">
        <hr />
      </div>

      <div className="uk-width-1-2">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][price]"}>Цена за 1 штуку для сайта:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][price]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][price]"}
            type="number"
            min="0"
            step="0.00001"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].price}
            placeholder={'Для расчетов на сайте'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-2">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][delivery]"}>Стоимость доставки для сайта:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][delivery]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][delivery]"}
            type="number"
            min="0"
            step="0.00001"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].delivery}
            placeholder={'Для расчетов на сайте'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][display][old]"}>Старая цена:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][display][old]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][display][old]"}
            type="text"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].display.old}
            placeholder={'100 000'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][display][new]"}>Новая цена:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][display][new]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][display][new]"}
            type="text"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].display.new}
            placeholder={'70 000'}
            required={true}
          />
        </div>
      </div>

      <div className="uk-width-1-3">
        <label className="uk-form-label" htmlFor={"formula[" + index + "][options][" + subIndex + "][display][discount]"}>Скидка:</label>
        <div className="uk-form-controls">
          <input
            id={"formula[" + index + "][options][" + subIndex + "][display][discount]"}
            className="uk-input"
            name={"formula[" + index + "][options][" + subIndex + "][display][discount]"}
            type="text"
            onChange={handleInputChange}
            value={formula[index].options[subIndex].display.discount}
            placeholder={'-30%'}
            required={true}
          />
        </div>
      </div>

    </div>

    </>
  );
}

export default Content;
