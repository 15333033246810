import React from 'react';

const Breadcrumb = ({children}) => {
  return (
    <li>
      {children}
    </li>
  )
}

export default Breadcrumb;
