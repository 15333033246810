import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyEditor = (props) => {

  const editorRef = useRef(null);

  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const filePickerCallback = function (cb, value, meta) {

    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.onchange = function () {
      var file = this.files[0];

      var reader = new FileReader();
      reader.onload = function () {

        var id = 'blobid' + (new Date()).getTime();
        var blobCache = editorRef.current.editorUpload.blobCache;
        // var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
        var base64 = reader.result.split(',')[1];
        var blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
  }

  if(!props.init.file_picker_callback){
    props.init.file_picker_callback = filePickerCallback;
  }

  if(!props.init.language){
    props.init.language = 'ru';
  }

  return (
    <Editor
     onInit={(evt, editor) => {editorRef.current = editor; console.log(editorRef)}}
     apiKey={apiKey}
     {...props}
   />

  )
}

export default TinyEditor;
