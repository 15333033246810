import { useState } from 'react';

export default function useAuthRole() {
  const getRole = () => {
    var data = localStorage.getItem('adminAuthRole');
    if(data == 'null'){
      data = null;
    }
    return data;
  };

  const [authRole, setAuthRole] = useState(getRole());

  const setRole = role => {
    localStorage.setItem('adminAuthRole', role);
    setAuthRole(role);
  };

  return {
    setAuthRole: setRole,
    authRole
  }
}
