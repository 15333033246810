import AbstractNotifications from './AbstractNotifications';

class UpsellsNotifications extends AbstractNotifications {
  messages = {
    'upsell_created': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Товар допродаж создан'
    },
    'upsell_updated': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },
    'upsell_deleted': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },

    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    },
    'existing_upsell_name': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Товар допродаж с таким названием уже существует'
    },
    'invalid_priority': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Приоритет вывода должен быть > 0'
    },
    'invalid_price': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Цена товара должна быть > 0'
    },
    'invalid_quantity': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Количество товара должно быть > 0'
    },
    'invalid_stock': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Остаток товара должен быть > 0'
    }
  }
}

export default UpsellsNotifications;
