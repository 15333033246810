import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { NotificationsContext } from '../../../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../../../components/app/contexts/ApiContext';

const headings = {
  'create': 'Создать категорию товаров допродаж',
  'update': 'Редактировать категорию товаров допродаж',
}


function CategoryModalScreen(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const categoryId = props.categoryId;

  const data = props.data;

  var tempName = '';
  if(categoryId > 0){
    data.forEach((item, i) => {
      if(item.id == categoryId){
        tempName = item.name;
      }
    });
  }

  const [name, setName] = useState(tempName);
  const [modal, setModal] = props.modal;
  const getData = props.getData;

  const heading = props.categoryId > 0 ? headings.update : headings.create;

  var modalStateClass = ' uk-open';


  const handleInputChange = (e) => {
    var {id, value} = e.target;

    if(id === 'name'){
      setName(value);
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(categoryId > 0){
      const response = await api.upsells_categories.update(
        categoryId,
        name
      );

      if(response.status === true){
        notifications.upsells_categories.notification('upsells_category_updated');

        setTimeout(async function(){
          await getData();
          await handleCancel(e);
        }, 2500);
      } else {
        notifications.upsells_categories.notification(response.error);
      }


    } else {

      const response = await api.upsells_categories.create(
        name
      );

      if(response.status === true){
        notifications.upsells_categories.notification('upsells_category_created');

        setTimeout(async function(){
          await getData();
          await handleCancel(e);
        }, 2500);
      } else {
        notifications.upsells_categories.notification(response.error);
      }

    }

    return false;
  }


  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setModal([]);
    return false;
  }



  return (

  <div id="categories-modal" className={"uk-modal" + modalStateClass} data-uk-modal style={{display: 'block'}}>
    <div className="uk-modal-dialog">
      <form id="hierModalForm" className="uk-form-stacked" onSubmit={handleSubmit}>
        <button className="uk-modal-close-default" data-uk-close type="button" onClick={handleCancel}></button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">{heading}</h2>
        </div>
        <div className="uk-modal-body">

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="name">Название:</label>
            <div className="uk-form-controls">
              <input
                id="name"
                name="name"
                value={name}
                onChange={handleInputChange}
                className="uk-input"
                placeholder="Товары для дома"
                required={true}
              />
            </div>
          </div>

        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default" type="button" onClick={handleCancel}>Отмена</button>
          <button className="uk-button uk-button-primary custom-button-success" type="submit">Сохранить</button>
        </div>
      </form>
    </div>
  </div>

  )
}

export default CategoryModalScreen;
