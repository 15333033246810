import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Block from "./Block";

function Blocks(props){

  const [upsells, setUpsells] = props.upsells;
  const upsellsMeta = props.upsellsMeta;
  const upsellsCategoriesMeta = props.upsellsCategoriesMeta;
  const typesMap = props.typesMap;


  const handleAdd = (event) => {

    var upsellsNew = [...upsells];

    upsellsNew.push({
      'type': 'upsell',
      'id': false
    })

    setUpsells(upsellsNew);
  }


  function renderUpsells(){

    var options = [];

    upsells.forEach((item, i) => {

      // Checks for the existing category
      var curUpsellMeta = false;
      if(item.type == 'upsell'){
        for (var j = 0; j < upsellsMeta.length; j++) {
          if(parseInt(upsellsMeta[j].id) == parseInt(item.id)){
            curUpsellMeta = upsellsMeta[j];
            break;
          }
        }
      } else if(item.type == 'category'){
        for (var j = 0; j < upsellsCategoriesMeta.length; j++) {
          if(parseInt(upsellsCategoriesMeta[j].id) == parseInt(item.id)){
            curUpsellMeta = upsellsCategoriesMeta[j];
            break;
          }
        }
      }

      // if(item.id !== false && curUpsellMeta === false){
      //   return;
      // }

      options.push(
        <li key={'upsell-' + i}>
          <Block
            upsells={[upsells, setUpsells]}
            upsellsMeta={upsellsMeta}
            upsellsCategoriesMeta={upsellsCategoriesMeta}
            typesMap={typesMap}
            index={i}
          />
        </li>
      );
    });

    var result = (
      <ul className="uk-grid-small uk-margin uk-child-width-1-1 site-upsells-wrapper" data-uk-grid>
        {options}

        <li>
          <div className="uk-card uk-card-default uk-card-small uk-card-body uk-text-center site-upsells-add" onClick={handleAdd}>
            <span className="uk-text-center" uk-icon="icon: plus"></span>
          </div>
        </li>
      </ul>
    );


    return result;
  }



  return (
    <>
      {renderUpsells()}
    </>
  );
}

export default Blocks;
