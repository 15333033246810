import AbstractNotifications from './AbstractNotifications';

class UsersNotifications extends AbstractNotifications {
  messages = {
    'user_created': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Пользователь создан'
    },
    'user_updated': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },
    'user_deleted': {
      'timeout': 1000,
      'status': 'success',
      'message': 'Ваши изменения сохранены'
    },

    'no_access': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Нет доступа'
    },
    'existing_user_login': {
      'timeout': 1000,
      'status': 'danger',
      'message': 'Пользователь с таким логином уже существует'
    }
  }
}

export default UsersNotifications;
