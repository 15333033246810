import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Categories from './categories/Categories';

function UpsellCategories(props){

  const [categories, setCategories] = props.categories;
  const categoriesMeta = props.categoriesMeta;

  return (
    <div className="uk-grid-small" data-uk-grid>

      <div className="uk-width-1-1" id="upsellCategoriesBox">
        <label className="uk-form-label">Категории:</label>
        <div className="uk-form-controls">
          <Categories
            categories={[categories, setCategories]}
            categoriesMeta={categoriesMeta}
          />
        </div>
      </div>

    </div>
  );
}

export default UpsellCategories;
