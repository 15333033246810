import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import filterOrderByIcon from '../../../assets/img/filter-left.svg';
import filterOrderIcon from '../../../assets/img/arrow-down-up.svg';
import filterDashboardUserIcon from '../../../assets/img/people-fill.svg';

function PixelsTokensTableFilter(props){

  const [filterOrderBy, setFilterOrderBy] = props.filterOrderBy;
  const [filterOrder, setFilterOrder] = props.filterOrder;
  const [filterSearch, setFilterSearch] = props.filterSearch;
  const [filterDashboardUser, setFilterDashboardUser] = props.filterDashboardUser;
  const [filterPage, setFilterPage] = props.filterPage;

  const getData = props.getData;

  const dashboardUsers = props.dashboardUsers;

  var dashboardUsersOptions = [];

  dashboardUsersOptions.push(
    <option key="dashboard-user-options-default" value="">-- Пользователь --</option>

  );
  Object.keys(dashboardUsers).forEach((item, i) => {
    dashboardUsersOptions.push(
      <option key={String('dashboard-user-options-'.concat(i))} value={item}>{item}</option>
    );
  });

  const handleFilterChange = async (e) => {
    e.preventDefault();

    var {id, value} = e.target;

    if(id == 'filterOrderBy'){
      await setFilterPage(1);
      await setFilterOrderBy(value);
    }

    if(id == 'filterOrder'){
      await setFilterPage(1);
      await setFilterOrder(value);
    }

    if(id == 'filterSearch'){
      await setFilterPage(1);
      await setFilterSearch(value);
    }

    if(id == 'filterDashboardUser'){
      await setFilterPage(1);
      await setFilterDashboardUser(value);
    }

  }


  return (
    <div className="uk-flex-right uk-grid-collapse" data-uk-grid>

      <div id="filterSearchDiv">
        <form className="uk-search uk-search-default">
          <span data-uk-search-icon></span>
          <input
            className="uk-search-input"
            type="search"
            id="filterSearch"
            name="filterSearch"
            placeholder="Поиск"
            value={filterSearch}
            onChange={handleFilterChange}
          />
        </form>
      </div>

      <div id="filterDashboardUserDiv">
        <picture>
          <img src={filterDashboardUserIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterDashboardUser"
          name="filterDashboardUser"
          value={filterDashboardUser}
          onChange={handleFilterChange}
        >
          {dashboardUsersOptions}
        </select>
      </div>

      <div id="filterOrderByDiv">
        <picture>
          <img src={filterOrderByIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrderBy"
          name="filterOrderby"
          value={filterOrderBy}
          onChange={handleFilterChange}
        >
          <option value="id">ID</option>
          <option value="pixel">Пиксель</option>
          <option value="access_token">Access Token</option>
          <option value="comment">Комментарий</option>
          <option value="dashboard_user">Пользователь</option>
          <option value="created">Создан</option>
          <option value="modified">Обновлен</option>
        </select>
      </div>


      <div id="filterOrderDiv">
        <picture>
          <img src={filterOrderIcon} />
        </picture>
        <select
          className="uk-select"
          id="filterOrder"
          name="filterOrder"
          value={filterOrder}
          onChange={handleFilterChange}
        >
          <option value="desc">По убыванию</option>
          <option value="asc">По возрастанию</option>
        </select>
      </div>

    </div>
  );
}

export default PixelsTokensTableFilter;
